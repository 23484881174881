import React, { useState, useEffect } from 'react';
import leftBgLine from '../../components/images/left_bg_line.png';

const YogaPosesDetail = ({ pose, onClose }) => {
    const [isMobile, setIsMobile] = useState(false);
    console.log(pose, "...........................????")
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="flex flex-col py-10 justify-start items-start px-5">
            <button
                className="bg-gradient-to-r from-[#F8EECC] to-[#EACA57] rounded-2xl  border border-black py-3 px-10 text-sm text-black font-normal text-center hover:bg-[#D7AB0F] cursor-pointer hover:underline mb-5"
                onClick={onClose}
            >
                Back to poses
            </button>
            <div className="flex flex-col mt-0 items-start ">
                <div
                    className='flex flex-row items-center  w-[100%] h-[40%] md:w-[100%] flex-wrap md:flex-nowrap'
                    style={{
                        backgroundImage: isMobile ? 'none' : `url(${leftBgLine})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                    }}
                >
                    <div className='w-[100%] md:w-[80%] pl-10'>
                        <h2 className="text-2xl font-semibold text-[#D7AB0F]">{pose.title}</h2>
                        <p className="text-lg text-black">{pose.description}</p>
                    </div>
                    <div className='md:p-5 flex md:w-[40%] w-[90%] py-5'>
                        <img src={pose.image} alt={pose.title} className="w-full" />
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-10'>
                <div className='flex flex-row gap-5 md:gap-10 w-50'>
                    <div className="bg-gradient-to-r from-[#F8EECC] to-[#EACA57] rounded-2xl border border-black p-10 mb-4 flex flex-col gap-5 text-center justify-center items-center">
                        <h3 className="text-xl font-bold text-black mb-2">Pose Type</h3>
                        <p className="text-[#101010]">{pose.poseType}</p>
                    </div>

                    {/* Sanskrit Meaning Card */}
                    <div className="bg-gradient-to-r from-[#F8EECC] to-[#EACA57] rounded-2xl border border-black p-10 mb-4 flex flex-col gap-5 text-center justify-center items-center">
                        <h3 className="text-xl font-bold text-black mb-2">Sanskrit Meaning</h3>
                        <p className="text-[#101010]">{pose.sanskritMeaning}</p>
                    </div>
                </div>
                <div className='flex flex-row gap-5 md:gap-10 w-70 md:ml-20'>
                    <div className="bg-gradient-to-r from-[#F8EECC] to-[#EACA57] rounded-2xl border border-black p-6 mb-4 flex flex-col gap-5">
                        <h3 className="text-xl font-bold text-black mb-2">Benefits</h3>
                        <ul className="text-[#101010] list-disc pl-5 grid grid-cols-2 gap-5"> {/* Added gap-5 */}
                            {pose.benefits.map((benefit, index) => (
                                <li key={index} className='mr-5'>{benefit}</li>
                            ))}
                        </ul>
                    </div>

                    {/* Targets Card */}
                    <div className="bg-gradient-to-r from-[#F8EECC] to-[#EACA57] rounded-2xl border border-black p-6 mb-4 flex flex-col gap-5">
                        <h3 className="text-xl font-bold text-black mb-2">Targets</h3>
                        <ul className="text-[#101010] list-disc pl-5 grid grid-cols-2 gap-5"> {/* Added gap-5 */}
                            {pose.targets.map((target, index) => (
                                <li key={index} className='mr-5'>{target}</li>
                            ))}
                        </ul>
                    </div>

                </div>
            </div>
            <div className='flex flex-col mt-10'>
                <div className='flex flex-col gap-5'>
                    <h3 className='text-4xl text-black font-bold'>Step-by-Step Guide</h3>
                    <h6 className='text-3xl text-black font-bold'>Here is the detailed step-by-step process for performing {pose.sanskritMeaning}:</h6>
                    <ul className='text-xl text-black font-normal list-disc'>
                        {
                            pose.stepByStepGuide.map((guide, index) => (
                                <>
                                    <li key={index}>{guide}</li>
                                    <br />
                                </>
                            ))
                        }
                    </ul>
                </div>
                <div className='flex flex-col gap-5 mt-10'>
                    <h3 className='text-4xl text-black font-bold mb-5'>Things You Need to Keep in Mind</h3>
                    {
                        pose.thingsToKeepInMind.map((things, index) => (
                            <>
                                <h6 className='text-3xl text-black font-bold'>{things.title}</h6>
                                <ul className='text-xl text-black font-normal list-disc'>
                                    <li key={index}>{things.description}</li>
                                </ul>

                            </>
                        ))
                    }
                </div>

            </div>
        </div>
    );
};

export default YogaPosesDetail;
