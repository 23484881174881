// Footer.js
import React, { useEffect, useState } from 'react';
import footerImageTop from '../images/footer_Img_top.png';
import footerImageBottom from '../images/footer_img_bottom.png';
import AppLogoWhite from '../images/AppLogo.PNG';
import InstaWhite from '../images/insta_white.png';
import FacebookWhite from '../images/facebook_white.png';
import YouTubeWhite from '../images/youtube_white.png';
import TwitterWhite from '../images/twitter_white.png';
import PinterestWhite from '../images/pintrust_white.png';
import LinkedInWhite from '../images/linkdin_white.png';
import EmailLogo from '../images/email_logo.png';
import AddressLogo from '../images/address_logo.png';
import { useNavigate } from 'react-router-dom';

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);


  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
};


const Footer = ({ onButtonClick }) => {
  const [activeButton, setActiveButton] = useState('HOME');
  const [browserZoomLevel, setBrowserZoomLevel] = useState(100);
  const isBigScreen = useMediaQuery('(min-width: 1024px)');
  const isPhoneScreen = useMediaQuery('(max-width: 768px)');
  const navigate = useNavigate()
  const handleButtonClick = (buttonName) => {
    console.log(`Button clicked: ${buttonName}`);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    setActiveButton(buttonName);
    onButtonClick(buttonName);

  };

  const detectZoom = () => {
    const zoom = Math.round(window.devicePixelRatio * 100);
    setBrowserZoomLevel(zoom);
  };

  useEffect(() => {
    window.addEventListener('resize', detectZoom);
    detectZoom();

    return () => {
      window.removeEventListener('resize', detectZoom);
    };
  }, []);


  return (
    <footer className="bg-[#D7AB0F] text-black text-center relative py-24  flex flex-col overflow-hidden">
      {isPhoneScreen && <div className="flex-1 text-left px-5 mb-10">
        <img src={AppLogoWhite} className="h-auto w-[70%] object-contain" alt="App Logo" />
        <h3 className="text-black text-sm font-normal leading-5 mt-6">
          With its roots in Indian Philosophy, Yog has become a spiritual practice and stress-managing exercise. With Yog as its foundation,<span className='font-bold text-base'> YOGSYNC </span> is your all-inclusive guide that aims to walk hand-in-hand through your journey. This platform aims to be the solution everyone needs from beginner to advanced-level yoga practitioners. Explore our detailed guides and articles and make the best of yoga practice.
        </h3>
        <ul className="flex gap-2 list-none mt-6">
          <li><img src={InstaWhite} alt="Instagram" className="h-auto w-auto filter brightness-0 opacity-100 hover:opacity-80" /></li>
          <li><img src={FacebookWhite} alt="Facebook" className="h-auto w-auto filter brightness-0 opacity-100 hover:opacity-80" /></li>
          <li><img src={YouTubeWhite} alt="YouTube" className="h-auto w-auto filter brightness-0 opacity-100 hover:opacity-80" /></li>
          <li><img src={TwitterWhite} alt="Twitter" className="h-auto w-auto filter brightness-0 opacity-100 hover:opacity-80" /></li>
          <li><img src={PinterestWhite} alt="Pinterest" className="h-auto w-auto filter brightness-0 opacity-100 hover:opacity-80" /></li>
          <li><img src={LinkedInWhite} alt="LinkedIn" className="h-auto w-auto filter brightness-0 opacity-100 hover:opacity-80" /></li>
        </ul>
      </div>}

      <div className="flex justify-evenly w-[90%] mx-auto relative z-2 sm:mx-10">

        <div className="flex w-full gap-8 flex-wrap">
          {
            isBigScreen && <div className="flex-1 text-left">
              <img src={AppLogoWhite} className="h-auto w-[70%] object-contain" alt="App Logo" />
              <h3 className="text-black text-sm font-normal leading-5 mt-5">

                {/* <span className="text-[black]"> read more...</span> */}

                With its roots in Indian Philosophy, Yog has become a spiritual practice and stress-managing exercise. With Yog as its foundation,<span className='font-bold text-base'> YOGSYNC </span> is your all-inclusive guide that aims to walk hand-in-hand through your journey. This platform aims to be the solution everyone needs from beginner to advanced-level yoga practitioners. Explore our detailed guides and articles and make the best of yoga practice.
              </h3>
              <ul className="flex gap-2 list-none mt-5">
                <li><img src={InstaWhite} alt="Instagram" className="h-auto w-auto filter brightness-0 opacity-100 hover:opacity-80" /></li>
                <li><img src={FacebookWhite} alt="Facebook" className="h-auto w-auto filter brightness-0 opacity-100 hover:opacity-80" /></li>
                <li><img src={YouTubeWhite} alt="YouTube" className="h-auto w-auto filter brightness-0 opacity-100 hover:opacity-80" /></li>
                <li><img src={TwitterWhite} alt="Twitter" className="h-auto w-auto filter brightness-0 opacity-100 hover:opacity-80" /></li>
                <li><img src={PinterestWhite} alt="Pinterest" className="h-auto w-auto filter brightness-0 opacity-100 hover:opacity-80" /></li>
                <li><img src={LinkedInWhite} alt="LinkedIn" className="h-auto w-auto filter brightness-0 opacity-100 hover:opacity-80" /></li>
              </ul>
            </div>
          }
          <div className="flex-1 text-left ml-20">
            <h3 className="text-xl font-serif">Important Links</h3>
            <ul className="list-none mt-10 ">

              <li className="mb-2 cursor-pointer hover:text-yellow-800" onClick={() => navigate("/")}>Home</li>
              <li className="mb-2 cursor-pointer hover:text-yellow-800" onClick={() => navigate("/articles")}>Articles</li>
              <li className="mb-2 cursor-pointer hover:text-yellow-800" onClick={() => navigate("/learn-yoga-poses")}>Learn Yoga Poses</li>

              <li className="mb-2 cursor-pointer hover:text-yellow-800" onClick={() => navigate("/about-us")}>About Us</li>
              <li className="mb-2 cursor-pointer hover:text-yellow-800" onClick={() => navigate("/contact-us")}>Contact Us</li>
              <li className="mb-2 cursor-pointer hover:text-yellow-800" onClick={() => handleButtonClick('BECOME A CONTRIBUTOR!')}>Become a Contributor!</li>

            </ul>
          </div>
          <div className="flex-1 text-left">
            <h3 className="text-xl font-serif ">Articles</h3>
            <ul className="list-none mt-10">
              <li className="mb-2 cursor-pointer hover:text-yellow-800">Practices</li>
              <li className="mb-2 cursor-pointer hover:text-yellow-800">Meditation</li>
              <li className="mb-2 cursor-pointer hover:text-yellow-800">Anatomy</li>
              <li className="mb-2 cursor-pointer hover:text-yellow-800">Philosophy</li>
              <li className="mb-2 cursor-pointer hover:text-yellow-800">Wellbeing</li>
            </ul>
          </div>
          <div className="flex-1 text-left">
            <h3 className="text-xl font-serif ">Support</h3>
            <ul className="list-none mt-10">
              <li className="flex items-center gap-2 mb-4">
                <img src={EmailLogo} alt="Email" className="h-4 w-auto filter brightness-0 opacity-100" />
                info@yogsync.com
              </li>
              <li className="flex items-center gap-2 mb-2">
                <img src={AddressLogo} alt="Address" className="h-6 w-auto filter brightness-0 opacity-100" />
                Plot No. F-204, Phase 8B, Industrial Area, < br />Sector 74, Sahibzada Ajit Singh Nagar,< br /> Punjab 160055
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 right-0">
        <img
          src={footerImageBottom}
          className="w-[80vw] h-[30] md:w-[15vw] md:h-[30vh] bg-transparent filter brightness-0 opacity-20"
          alt="Footer Background"
        />
      </div>
      <div className="absolute top-0 md:left-1/2 md:transform md:-translate-x-1/2">
        <img
          src={footerImageTop}
          className="w-[80vw] h-[30vh] md:w-[25vw] md:h-[30vh] bg-transparent filter brightness-0 opacity-20"
          alt="Footer Background"
        />
      </div>
    </footer>
  );
};

export default Footer;
