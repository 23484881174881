import api from "./api";

export const fetchLatestArticles = async () => {
    try {
      const response = await api.get('/user/artical/latest/get');
      return response.data;
    } catch (error) {
      console.error('Failed to fetch latest articles:', error);
      throw error;
    }
  };

  export const fetchAllArticles = async () => {
    try {
      const response = await api.get('/user/artical/getall');
      return response.data;
    } catch (error) {
      console.error('Failed to fetch latest articles:', error);
      throw error;
    }
  };

  export const fetchVideo = async () => {
    try {
      const response = await api.get('/video/getall');
      return response.data;
    } catch (error) {
      console.error('Failed to fetch latest articles:', error);
      throw error;
    }
  };

  export const fetchAllYogaPoses = async () => {
    try {
        const response = await api.get('/user/yogapose/getall');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch yoga poses:', error);
        throw error;
    }
};

export const fetchAllYogaDetails = async (pose_id) => {
  try {
      const response = await api.get(`user/yogapose/details/get?pose_id=${pose_id}`);
      return response.data;
  } catch (error) {
      console.error('Failed to fetch yoga poses:', error);
      throw error;
  }
};


export const fetchExpertApprovedDetails = async () => {
  try {
      const response = await api.get(`/user/artical/expertapproved/get`);
      return response.data;
  } catch (error) {
      console.error('Failed to fetch yoga poses:', error);
      throw error;
  }
};
  