import axios from 'axios';

const api = axios.create({
  // baseURL: 'https://www.certifit.in/yogsync',
  baseURL:'https://yogsync.com/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

export default api;
