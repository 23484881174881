// import React, { useEffect, useState } from 'react';
// import MenuModal from '../Model/menuModal';
// import BecomeContributor from '../Model/becomeContributor';

// const Header = ({ onButtonClick }) => {
//   const [activeButton, setActiveButton] = useState('HOME');
//   const [browserZoomLevel, setBrowserZoomLevel] = useState(100);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isContributorModalOpen, setIsContributorOpen] = useState(false);

//   const handleButtonClick = (buttonName) => {
//     setActiveButton(buttonName);
//     onButtonClick(buttonName);
//   };

//   const detectZoom = () => {
//     const zoom = Math.round(window.devicePixelRatio * 100);
//     setBrowserZoomLevel(zoom);
//   };

//   useEffect(() => {
//     window.addEventListener('resize', detectZoom);
//     detectZoom();

//     return () => {
//       window.removeEventListener('resize', detectZoom);
//     };
//   }, []);

//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);


//   const openContributorModal = () => setIsContributorOpen(true);
//   const closeContributorModal = () => setIsContributorOpen(false);

//   return (
//     <>
//       <header className="flex justify-center py-3 w-full border-b border-gray-300 bg-white">
//         {(browserZoomLevel >= 150 || browserZoomLevel <= 75) ? (
//           <div className="flex items-center justify-between px-10 w-full">
//             <img src={require('../images/AppLogo.PNG')} className="h-15 w-auto" alt="App Logo" />
//             <button className="border-none bg-transparent cursor-pointer" onClick={openModal}>
//               <img src={require('../images/menu.png')} className="h-5 w-5 filter brightness-0 opacity-90" alt="Menu" />
//             </button>
//           </div>
//         ) : (
//           <div className="flex flex-col items-center w-full">
//             <img src={require('../images/AppLogo.PNG')} className="h-[10vh] w-auto" alt="App Logo" />
//             <div className="flex flex-row w-full mt-2 mb-1 items-center justify-center">
//               <button
//                 onClick={() => handleButtonClick('HOME')}
//                 className={`text-lg text-black border-none bg-transparent cursor-pointer transition-colors duration-300 font-normal mx-6 my-2 font-poppins hover:text-[#D7AB0F] ${activeButton === 'HOME' ? 'text-[#D7AB0F]' : ''
//                   }`}
//               >
//                 Home
//               </button>
//               <div className="h-3 bg-[#D7AB0F] w-0.5 mt-3 mb-3"></div>
//               <button
//                 onClick={() => handleButtonClick('ARTICLES')}
//                 className={`text-lg text-black border-none bg-transparent cursor-pointer transition-colors duration-300 font-normal mx-6 my-2 font-poppins hover:text-[#D7AB0F] ${activeButton === 'ARTICLES' ? 'text-[#D7AB0F]' : ''
//                   }`}
//               >
//                 Articles
//               </button>
//               <div className="h-3 bg-[#D7AB0F] w-0.5 mt-3 mb-3"></div>
//               <button
//                 onClick={() => handleButtonClick('LEARN YOGA POSES')}
//                 className={`text-lg text-black border-none bg-transparent cursor-pointer transition-colors duration-300 font-normal mx-6 my-2 font-poppins hover:text-[#D7AB0F] ${activeButton === 'LEARN YOGA POSES' ? 'text-[#D7AB0F]' : ''
//                   }`}
//               >
//                 Learn Yoga Poses
//               </button>
//               <div className="h-3 bg-[#D7AB0F] w-0.5 mt-3 mb-3"></div>
//               <button
//                 onClick={() => handleButtonClick('VIDEO GALLERY')}
//                 className={`text-lg text-black border-none bg-transparent cursor-pointer transition-colors duration-300 font-normal mx-6 my-2 font-poppins hover:text-[#D7AB0F] ${activeButton === 'VIDEO GALLERY' ? 'text-[#D7AB0F]' : ''
//                   }`}
//               >
//                 Video Gallery
//               </button>
//               <div className="h-3 bg-[#D7AB0F] w-0.5 mt-3 mb-3"></div>
//               <button
//                 onClick={() => handleButtonClick('ABOUT US')}
//                 className={`text-lg text-black border-none bg-transparent cursor-pointer transition-colors duration-300 font-normal mx-6 my-2 font-poppins hover:text-[#D7AB0F] ${activeButton === 'ABOUT US' ? 'text-[#D7AB0F]' : ''
//                   }`}
//               >
//                 About Us
//               </button>
//               <div className="h-3 bg-[#D7AB0F] w-0.5 mt-3 mb-3"></div>
//               <button
//                 onClick={() => handleButtonClick('CONTACT US')}
//                 className={`text-lg text-black border-none bg-transparent cursor-pointer transition-colors duration-300 font-normal mx-6 my-2 font-poppins hover:text-[#D7AB0F] ${activeButton === 'CONTACT US' ? 'text-[#D7AB0F]' : ''
//                   }`}
//               >
//                 Contact Us
//               </button>
//               <div className="h-3 bg-[#D7AB0F] w-0.5 mt-3 mb-3"></div>
//               <button className="bg-[#D7AB0F] rounded-full py-3 px-4 text-lg text-black font-normal text-center border-none font-poppins ml-6 hover:bg-[#D7AB0F] cursor-pointer"
//                 //  onClick={openContributorModal}
//                 onClick={() => handleButtonClick('BECOME A CONTRIBUTOR!')}
//               >
//                 Become a Contributor!
//               </button>
//             </div>
//           </div>
//         )}
//       </header>

//       <MenuModal
//         isOpen={isModalOpen}
//         onRequestClose={closeModal}
//         onMenuButtonClick={handleButtonClick}
//       />

//       <BecomeContributor isOpen={isContributorModalOpen}
//         onRequestClose={closeContributorModal} />
//     </>
//   );
// };

// export default Header;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MenuModal from '../Model/menuModal';
import BecomeContributor from '../Model/becomeContributor';

const Header = ({ onButtonClick }) => {
  const [activeButton, setActiveButton] = useState('HOME');
  const [browserZoomLevel, setBrowserZoomLevel] = useState(100);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isContributorModalOpen, setIsContributorOpen] = useState(false);

  const detectZoom = () => {
    const zoom = Math.round(window.devicePixelRatio * 100);
    setBrowserZoomLevel(zoom);
  };

  useEffect(() => {
    window.addEventListener('resize', detectZoom);
    detectZoom();

    return () => {
      window.removeEventListener('resize', detectZoom);
    };
  }, []);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const openContributorModal = () => setIsContributorOpen(true);
  const closeContributorModal = () => setIsContributorOpen(false);

  return (
    <>
      <header className="flex justify-center py-3 w-full border-b border-gray-300 bg-white">
        {(browserZoomLevel >= 150 || browserZoomLevel <= 75) ? (
          <div className="flex items-center justify-between px-10 w-full">
            <img src={require('../images/AppLogo.PNG')} className="h-12 w-auto" alt="App Logo" />
            <button className="border-none bg-transparent cursor-pointer" onClick={openModal}>
              <img src={require('../images/menu.png')} className="h-5 w-5 filter brightness-0 opacity-90" alt="Menu" />
            </button>
          </div>
        ) : (
          <div className="flex flex-col items-center w-full">
            <img src={require('../images/AppLogo.PNG')} className="h-[10vh] w-auto" alt="App Logo" />
            <div className="flex flex-row w-full mt-2 mb-1 items-center justify-center">
              <Link
                to="/"
                className={`text-lg text-black border-none bg-transparent cursor-pointer transition-colors duration-300 font-normal mx-6 my-2 font-poppins hover:text-[#D7AB0F] ${activeButton === 'HOME' ? 'text-[#D7AB0F]' : ''
                  }`}
                onClick={() => setActiveButton('HOME')}
              >
                Home
              </Link>
              <div className="h-3 bg-[#D7AB0F] w-0.5 mt-3 mb-3"></div>
              <Link
                to="/articles"
                className={`text-lg text-black border-none bg-transparent cursor-pointer transition-colors duration-300 font-normal mx-6 my-2 font-poppins hover:text-[#D7AB0F] ${activeButton === 'ARTICLES' ? 'text-[#D7AB0F]' : ''
                  }`}
                onClick={() => setActiveButton('ARTICLES')}
              >
                Articles
              </Link>
              <div className="h-3 bg-[#D7AB0F] w-0.5 mt-3 mb-3"></div>
              <Link
                to="/learn-yoga-poses"
                className={`text-lg text-black border-none bg-transparent cursor-pointer transition-colors duration-300 font-normal mx-6 my-2 font-poppins hover:text-[#D7AB0F] ${activeButton === 'LEARN YOGA POSES' ? 'text-[#D7AB0F]' : ''
                  }`}
                onClick={() => setActiveButton('LEARN YOGA POSES')}
              >
                Learn Yoga Poses
              </Link>
              <div className="h-3 bg-[#D7AB0F] w-0.5 mt-3 mb-3"></div>
              {/* <Link
                to="/video-gallery"
                className={`text-lg text-black border-none bg-transparent cursor-pointer transition-colors duration-300 font-normal mx-6 my-2 font-poppins hover:text-[#D7AB0F] ${activeButton === 'VIDEO GALLERY' ? 'text-[#D7AB0F]' : ''
                  }`}
                onClick={() => setActiveButton('VIDEO GALLERY')}
              >
                Video Gallery
              </Link> */}
              <div className="h-3 bg-[#D7AB0F] w-0.5 mt-3 mb-3"></div>
              <Link
                to="/about-us"
                className={`text-lg text-black border-none bg-transparent cursor-pointer transition-colors duration-300 font-normal mx-6 my-2 font-poppins hover:text-[#D7AB0F] ${activeButton === 'ABOUT US' ? 'text-[#D7AB0F]' : ''
                  }`}
                onClick={() => setActiveButton('ABOUT US')}
              >
                About Us
              </Link>
              <div className="h-3 bg-[#D7AB0F] w-0.5 mt-3 mb-3"></div>
              <Link
                to="/contact-us"
                className={`text-lg text-black border-none bg-transparent cursor-pointer transition-colors duration-300 font-normal mx-6 my-2 font-poppins hover:text-[#D7AB0F] ${activeButton === 'CONTACT US' ? 'text-[#D7AB0F]' : ''
                  }`}
                onClick={() => setActiveButton('CONTACT US')}
              >
                Contact Us
              </Link>
              <div className="h-3 bg-[#D7AB0F] w-0.5 mt-3 mb-3"></div>
              <button
                className="bg-[#D7AB0F] rounded-full py-3 px-4 text-lg text-black font-normal text-center border-none font-poppins ml-6 hover:bg-[#D7AB0F] cursor-pointer"
                onClick={() => openContributorModal()}
              >
                Become a Contributor!
              </button>
            </div>
          </div>
        )}
      </header>

      <MenuModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        onMenuButtonClick={onButtonClick}
      />

      <BecomeContributor
        isOpen={isContributorModalOpen}
        onRequestClose={closeContributorModal}
      />
    </>
  );
};

export default Header;
