import React, { useEffect, useState } from 'react';
import LearnYogaPosesDetail from '../../LearnYogaPoses/components/LearnYogaPosesDetail';
import DOMPurify from 'dompurify';
import { Link } from 'react-router-dom';

const HtmlRenderer = ({ htmlContent, styleType, isList }) => {
    const sanitizeHtml = (html) => {
        return DOMPurify.sanitize(html);
    };
  
    const applyListStyles = (html) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
  
        const uls = tempDiv.querySelectorAll('ul');
        const ols = tempDiv.querySelectorAll('ol');
  
        uls.forEach((ul) => {
            if (isList) {
                ul.classList.add('list-disc', 'pl-5', 'pr-5', 'grid', 'grid-cols-1', 'gap-2', 'md:gap-x-20', 'md:gap-y-0', 'md:grid-cols-2');
            } else {
                ul.classList.add('list-disc', 'pl-5');
            }
        });
  
        ols.forEach((ol) => {
            ol.classList.add('list-decimal', 'pl-5');
        });
  
        return tempDiv.innerHTML;
    };


  
    const processedHtml = applyListStyles(sanitizeHtml(htmlContent));
  
    const getStyleClass = () => {
        switch (styleType) {
            case 'short':
                return 'text-medium font-medium text-text-primary'; // Apply specific Tailwind classes
            case 'long':
                return 'text-large font-medium text-text-secondary w-[90%]'; // Apply different Tailwind classes
            case 'list':
                return 'text-text-highlight font-medium pr-10'; // Style for lists
            default:
                return 'text-base text-text-primary'; // Default styling
        }
    };
  
    return (
        <div
            className={getStyleClass()}
            dangerouslySetInnerHTML={{ __html: processedHtml }}
        />
    );
  };
const VideoGalleryDetails = ({ video, onClose }) => {
    console.log(video, "video data..");

    const [videoId, setVideoId] = useState('');
    const [selectedPose, setSelectedPose] = useState(null);

    const handlePoseClick = (pose) => {
        setSelectedPose(pose);
    };

    const extractVideoId = (url) => {
        if (!url) return '';
        const match = url.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:embed\/|watch\?v=|v\/)?([^"&?\/\s]{11})/);
        return match ? match[1] : '';
    };

    useEffect(() => {
        if (video && video.url) {
            const id = extractVideoId(video.url);
            setVideoId(id);
        }
    }, [video]);

    if (selectedPose) {
        return (
            <LearnYogaPosesDetail
                pose={selectedPose}
                onClose={() => setSelectedPose(null)}
                activeTab="LearnYogaPosesDetail"
            />
        );
    }
    const createSlug = (title) => {
        return title
          .toLowerCase()         // Convert to lowercase
          .replace(/\s+/g, '-')  // Replace spaces with hyphens
          .replace(/[^\w\-]+/g, '') // Remove non-alphanumeric characters except hyphen
          .replace(/\-\-+/g, '-')  // Replace multiple hyphens with a single hyphen
          .trim(); // Remove leading/trailing hyphens
      };
    // Ensure related_yoga_poses is always an array
    const relatedYogaPoses = video?.related_yoga_poses || [];

    return (
        <div className="article-detail-view bg-white my-10 ">
            <div className="flex flex-col px-3 md:px-5 lg:px-5">
                <div className='flex flex-col md:flex-row-reverse gap-10 lg:flex-row-reverse'>
                    <div className='flex w-[100%] md:w-[60%] h-auto lg:w-[60%]'>
                        {videoId ? (
                            <div className="flex relative w-[100%] md:w-[90%] lg:w-[90%] h-auto">
                                <iframe
                                    width="100%"
                                    height="100%"
                                    src={`https://www.youtube.com/embed/${videoId}`}
                                    title={video.title}
                                    className="w-full h-auto object-cover"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        ) : (
                            <div className="text-red-500">Thumbnail not available</div>
                        )}
                    </div>

                    <div className='flex flex-col gap-5 md:gap-10 lg:gap-10 items-start justify-start relative py-0 md:py-0 lg:py-0 md:w-[50%] lg:w-[50%]'>
                        {/* <img
                            src={require('../../../components/images/yoga_bg.png')}
                            className="absolute top-0 right-0 left-0 bottom-0 opacity-[0.1]"
                            alt=""
                        /> */}
                        <div className='flex flex-row items-center gap-5 relative'>
                            <button
                                className="bg-[#D7AB0F] rounded-[5px] py-3 px-4 text-sm text-black font-normal text-center border-none font-poppins hover:bg-[#D7AB0F] cursor-pointer hover:underline"
                                onClick={onClose}
                            >
                                Back to Videos
                            </button>
                        </div>

                        <p className="text-[#D7AB0F] text-3xl font-bold text-start">{video.title}</p>
                        {/* <p className="text-black text-lg font-normal text-start">{video.description}</p> */}
                        <HtmlRenderer htmlContent={video.description} styleType="short" />
                        <div className='flex flex-row gap-5 relative'>
                            <a
                                href="https://www.facebook.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Facebook"
                            >
                                <img src={require('../../../components/images/facebook_gray.png')} className='w-[25px] h-[25px] object-cover filter brightness-0 opacity-100' alt="Facebook" />
                            </a>
                            <a
                                href="https://twitter.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Twitter"
                            >
                                <img src={require('../../../components/images/twitter_gray.png')} className='w-[25px] h-[25px] object-cover filter brightness-0 opacity-100' alt="Twitter" />
                            </a>
                            <a
                                href="https://www.pinterest.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Pinterest"
                            >
                                <img src={require('../../../components/images/pintrust_gray.png')} className='w-[25px] h-[25px] object-cover filter brightness-0 opacity-100' alt="Pinterest" />
                            </a>
                            <a
                                href="https://wa.me/"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="WhatsApp"
                            >
                                <img src={require('../../../components/images/whatsapp_gray.png')} className='w-[25px] h-[25px] object-cover filter brightness-0 opacity-100' alt="WhatsApp" />
                            </a>
                        </div>
                    </div>
                </div>

                <div className='my-10 w-[100%] flex flex-col'>
                    <div className='flex flex-col gap-5 border rounded-lg border-[#fcf2cf] z-10 py-10 px-5 md:px-10 md:mx-5'>
                        <p className="text-[#D7AB0F] text-2xl font-bold text-start mt-10">{video.title}</p>
                        {/* <p className="text-black text-xl font-semibold text-start">{video.description}</p> */}
                        <HtmlRenderer htmlContent={video.description} styleType="short" />
                    </div>

                    {relatedYogaPoses.length > 0 && (
                        <div className='flex flex-col  justify-center px-10 md:px-10 lg:px-10 border rounded-lg border-[#fcf2cf] z-10 py-10 md:mx-5' >
                            <p className='text-[#D7AB0F] text-3xl font-bold text-start mt-10 mb-5 -ml-10 md:-ml-10 lg:-ml-10'>Related Yoga Poses</p>
                            {relatedYogaPoses.map((item, index) => (
                                <Link
                                    key={index}
                                    className='flex  cursor-pointer mb-2'
                                    // onClick={() => handlePoseClick(item)}
                                    to={{
                                        pathname: `/learn-yoga-poses/${item.id}/${createSlug(item.name)}`, 
                                        // state: poseData , 
                                      }}
                                >
                                    <td className="text-black text-lg list-item underline font-bold  mt-0 mb-0 ">{item.name}</td>

                                </Link>
                            ))}
                        </div>
                    )}

                    <div className='my-10 w-[100%] flex flex-col items-center justify-center'>
                        <h4 className="text-[#D7AB0F] text-2xl font-semibold text-start mt-10">Share Video</h4>
                        <div className='flex flex-row gap-8 mt-5'>
                            <a
                                href="https://www.facebook.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Facebook"
                            >
                                <img src={require('../../../components/images/facebook_gray.png')} className='w-[25px] h-[25px] object-cover filter brightness-0 opacity-100' alt="Facebook" />
                            </a>
                            <a
                                href="https://twitter.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Twitter"
                            >
                                <img src={require('../../../components/images/twitter_gray.png')} className='w-[25px] h-[25px] object-cover filter brightness-0 opacity-100' alt="Twitter" />
                            </a>
                            <a
                                href="https://www.pinterest.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Pinterest"
                            >
                                <img src={require('../../../components/images/pintrust_gray.png')} className='w-[25px] h-[25px] object-cover filter brightness-0 opacity-100' alt="Pinterest" />
                            </a>
                            <a
                                href="https://wa.me/"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="WhatsApp"
                            >
                                <img src={require('../../../components/images/whatsapp_gray.png')} className='w-[25px] h-[25px] object-cover filter brightness-0 opacity-100' alt="WhatsApp" />
                            </a>
                            <a
                                href="javascript:void(0);"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Copy Link"
                            >
                                <img src={require('../../../components/images/copyLink_gray.png')} className='w-[25px] h-[25px] object-cover filter brightness-0 opacity-100' alt="Copy Link" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoGalleryDetails;
