import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Service ID:service_fwph7h9
// yogsync@5312 , info@yogsync.com,9779718779
const ContactUs = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phone: '',
        message: '',
    });
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);

    const validate = () => {
        const newErrors = {};

        if (!formData.fullName.trim()) newErrors.fullName = 'Full Name is required';
        if (!formData.email.trim()) newErrors.email = 'Email Address is required';
        else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Email Address is invalid';
        // if (!formData.phone.trim()) newErrors.phone = 'Phone Number is required';
        if (!formData.message.trim()) newErrors.message = 'Message is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({});
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     setSubmitted(true);

    //     if (validate()) {
    //         console.log('Form submitted', formData);
    //         setFormData({ fullName: '', email: '', phone: '', message: '' });
    //         setErrors({});
    //     }
    // };
    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        const emailData = {
            to_name: 'Yogsync',
            from_name: formData.fullName,
            message: formData.message,
            from_email: formData.email
        };
        if (validate()) {
            emailjs.send('service_fwph7h9', 'template_8ye2mar', emailData, 'WcSa42N6XWzJw9Ld2')
                .then((response) => {
                    console.log('Email sent successfully:', response.status, response.text);
                    console.log(response, "response..")
                    toast("Your message has been sent successfully!")
                    setFormData({ fullName: '', email: '', phone: '', message: '' });
                    setErrors({});
                })
                .catch((error) => {
                    console.error('Error sending email:', error);

                    toast.error("There was an error sending your message. Please try again.")
                });
        }
    };

    return (
        <div className="flex flex-col bg-white pb-10 z-20 gap-10 py-10 px-5 md:py-0 lg:px-0 md:px-0 lg:py-0">
            <ToastContainer />
            {/* <div className="bg-[#fdf9e7] relative">
                <img src={require('../../components/images/yoga_bg.png')} className="absolute top-10 right-0 bottom-0 left-10 md:left-[30vw] lg:left-[30vw] h-1/1 w-auto opacity-[0.1] z-0" layout="fill" objectFit="cover" alt="Background Image" />
                <img src={require('../../components/images/yoga_bg.png')} className="absolute bottom-0 -left-10 -top-10 right-0 md:right-[30vw] lg:right-[30vw] h-[200px] w-auto opacity-[0.1] z-0" layout="fill" objectFit="cover" alt="Background Image" />
                <div className='p-5 pt-10 pb-12 pl-18 z-10'>
                    <h1 className="text-[#D7AB0F] text-4xl font-semibold leading-[48px] font-serif mb-0">Connect with Us</h1>
                    <h6 className="text-[#D7AB0F] text-base font-semibold text-left mt-7 mb-7 w-[100%] md:w-[70%] lg:w-[70%] font-serif">
                        Your thoughts and feedback help us improve. Reach out with any questions or comments, and we'll be in touch!
                    </h6>
                    <div className='flex flex-row gap-5 relative'>
                        <a
                            href="https://www.facebook.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Facebook"
                        >
                            <img src={require('../../components/images/facebook_gray.png')} className='w-[25px] h-[25px] object-cover' alt="Facebook" />
                        </a>
                        <a
                            href="https://twitter.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Twitter"
                        >
                            <img src={require('../../components/images/twitter_gray.png')} className='w-[25px] h-[25px] object-cover' alt="Twitter" />
                        </a>
                        <a
                            href="https://www.pinterest.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Pinterest"
                        >
                            <img src={require('../../components/images/pintrust_gray.png')} className='w-[25px] h-[25px] object-cover' alt="Pinterest" />
                        </a>
                        <a
                            href="https://wa.me/"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="WhatsApp"
                        >
                            <img src={require('../../components/images/whatsapp_gray.png')} className='w-[25px] h-[25px] object-cover' alt="WhatsApp" />
                        </a>
                    </div>
                </div>
            </div> */}
            <div className="bg-[#fdf9e7] relative" style={{ backgroundImage: `url(${require('../../components/images/section_Header.png')})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>

                <div className=' p-5 pt-10 pb-12 pl-18 z-10'>
                    <h1 className="text-[#6D5302] text-4xl font-semibold leading-[48px] font-serif mb-0">Connect with Us</h1>
                    <h6 className="text-[#FFFEFE] text-base font-semibold text-left mt-7 mb-7 md:w-[60%] w-[90%]  font-serif">
                        Are you looking for a platform to share your knowledge of yoga with the world? You’re at the right place! Share your details, and let’s proceed with the process.
                    </h6>

                    <div className='flex flex-row gap-5 relative'>
                        <a
                            href="https://www.facebook.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Facebook"
                        >
                            <img src={require('../../components/images/facebook_gray.png')} className='w-[25px] h-[25px] filter brightness-0 opacity-100 hover:opacity-80 object-cover' alt="Facebook" />
                        </a>
                        <a
                            href="https://twitter.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Twitter"
                        >
                            <img src={require('../../components/images/twitter_gray.png')} className='w-[25px] h-[25px] filter brightness-0 opacity-100 hover:opacity-80 object-cover' alt="Twitter" />
                        </a>
                        <a
                            href="https://www.pinterest.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Pinterest"
                        >
                            <img src={require('../../components/images/pintrust_gray.png')} className='w-[25px] h-[25px] filter brightness-0 opacity-100 hover:opacity-80 object-cover' alt="Pinterest" />
                        </a>
                        <a
                            href="https://wa.me/"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="WhatsApp"
                        >
                            <img src={require('../../components/images/whatsapp_gray.png')} className='w-[25px] h-[25px] filter brightness-0 opacity-100 hover:opacity-80 object-cover' alt="WhatsApp" />
                        </a>
                    </div>
                </div>
            </div>
            <form onSubmit={handleSubmit} className="border rounded-lg border-[#fcf2cf] z-10 py-10 px-5 md:px-10 md:mx-5">
                <h1 className="text-[#D7AB0F] text-4xl font-semibold leading-[48px] font-serif mb-0">Contact Form</h1>
                <div className="flex flex-col gap-5 mt-10">
                    <div className="flex flex-col gap-5">
                        <p className="text-black text-xl font-semibold text-left">
                            Full Name:
                        </p>
                        <input
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange}
                            placeholder='Enter your full name'
                            className={`border rounded-lg py-3 px-5 w-[100%] md:w-[90%] lg:w-[90%] ${errors.fullName ? 'border-red-500' : 'border-[#D7AB0F]'}`}
                        />
                        {submitted && errors.fullName && <p className="text-red-500 text-sm">{errors.fullName}</p>}
                    </div>
                    <div className="flex flex-col gap-5">
                        <p className="text-black text-xl font-semibold text-left">
                            Email Address:
                        </p>
                        <input
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder='Enter your email address'
                            className={`border rounded-lg py-3 px-5 w-[100%] md:w-[90%] lg:w-[90%] ${errors.email ? 'border-red-500' : 'border-[#D7AB0F]'}`}
                        />
                        {submitted && errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                    </div>
                    <div className="flex flex-col gap-5">
                        <p className="text-black text-xl font-semibold text-left">
                            Phone Number:
                        </p>
                        <input
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            placeholder='Enter your phone number here'
                            className={`border rounded-lg py-3 px-5 w-[100%] md:w-[90%] lg:w-[90%] ${errors.phone ? 'border-red-500' : 'border-[#D7AB0F]'}`}
                        />
                        {submitted && errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
                    </div>
                    <div className="flex flex-col gap-5">
                        <p className="text-black text-xl font-semibold text-left">
                            Message
                        </p>
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            placeholder='Enter your message or inquiry here'
                            className={`border rounded-lg py-3 h-[20vh] px-5 w-[100%] md:w-[90%] lg:w-[90%] ${errors.message ? 'border-red-500' : 'border-[#D7AB0F]'}`}
                        />
                        {submitted && errors.message && <p className="text-red-500 text-sm">{errors.message}</p>}
                    </div>
                    <button
                        type="submit"
                        className="bg-[#D7AB0F] text-black w-[70%] md:w-[20%] lg:w-[20%] rounded-full py-3 px-5 text-base font-normal hover:bg-[#D7AB0F] "
                    >
                        Send Message
                    </button>
                </div>
            </form>
            <div className='flex flex-row justify-between w-full gap-10 mb-5 flex-wrap md:flex-nowrap'>
                <div className="border rounded-lg  border-[#fcf2cf] z-20 py-10 px-5 md:px-10 md:mx-5 w-[100%] md:w-[90%] md:ml-5">
                    <h1 className="text-[#D7AB0F] text-4xl font-semibold leading-[48px] font-serif mb-0">Our Contact Details</h1>
                    <div className="flex flex-col gap-5 mt-10">
                        <div className="flex flex-row gap-5 items-center">
                            <img src={require("../../components/images/address_logo.png")} className="h-6 w-auto filter brightness-0 opacity-90" alt="Address" />
                            <p className="text-[#D7AB0F] text-base font-semibold text-left">
                                Plot No. F-204, Phase 8B, Industrial Area, < br />Sector 74, Sahibzada Ajit Singh Nagar,< br /> Punjab 160055
                            </p>
                        </div>
                        <div className="flex flex-row gap-5 items-center">
                            <img src={require("../../components/images/phone.png")} className="h-5 w-auto filter brightness-0 opacity-90" alt="Phone" />
                            <p className="text-[#D7AB0F] text-base font-semibold text-left">
                                +91 6280144510
                            </p>
                        </div>
                        <div className="flex flex-row gap-5 items-center">
                            <img src={require("../../components/images/email_logo.png")} className="h-5 w-auto filter brightness-0 opacity-90" alt="Email" />
                            <p className="text-[#D7AB0F] text-base font-semibold text-left">
                                info@yogsync.com
                            </p>
                        </div>
                    </div>
                </div>

                <div className="border rounded-lg border-[#fcf2cf] z-10 py-10 px-5 md:px-10 md:mx-5 w-[100%] md:w-[90%]  mr-5">
                    <h1 className="text-[#D7AB0F] text-4xl font-semibold leading-[48px] font-serif mb-0">Business Hours</h1>
                    <div className="flex flex-col gap-5 mt-10">
                        <p className="text-[#D7AB0F] text-base font-semibold text-left">
                            Monday - Friday : <span className='text-black'>9:00 AM - 6:00 PM</span>
                        </p>
                        <p className="text-[#D7AB0F] text-base font-semibold text-left">
                            Saturday : <span className='text-black'>10:00 AM - 4:00 PM</span>
                        </p>
                        <p className="text-[#D7AB0F] text-base font-semibold text-left">
                            Sunday : <span className='text-black'>Closed</span>
                        </p>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default ContactUs;
