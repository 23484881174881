import React, { useState } from 'react';
import ArticlesTabs from './components/ArticlesTabs';
import ArticlesDetails from './components/ArticlesDetails';

const Articles = () => {
  const [selectedArticle, setSelectedArticle] = useState(null);

  const [activeTab, setActiveTab] = useState('All');

  const handleArticleClick = (article) => {
    setSelectedArticle(article);
  };
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleCloseDetails = () => {
    setSelectedArticle(null);
  };

  return (
    <div className="flex flex-col bg-[#fdf9e7] z-20">
      {/* {selectedArticle ? (

        <ArticlesDetails article={selectedArticle} onClose={handleCloseDetails} activeTab={activeTab} />

      ) : ( */}
        <>
          <div className="bg-[#fdf9e7] relative" style={{ backgroundImage: `url(${require('../../components/images/section_Header.png')})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
            {/* <img src={require('../../components/images/yoga_bg.png')} className="absolute top-20 right-0 bottom-0 left-10 md:left-[30vw] lg:left-[30vw] h-1/1 w-auto opacity-[0.1] z-0" layout="fill" objectFit="cover" alt="Background Image" />
            <img src={require('../../components/images/yoga_bg.png')} className="absolute bottom-0 -left-10 -top-10 right-0 md:right-[30vw] lg:right-[30vw] h-[200px] w-auto opacity-[0.1] z-0" layout="fill" objectFit="cover" alt="Background Image" /> */}
            <div className=' p-5 pt-10 pb-12 pl-18 z-10'>
              <h1 className="text-[#6D5302] text-4xl font-semibold leading-[48px] font-serif mb-0">Articles</h1>
              <h6 className="text-[#FFFEFE] text-base font-semibold text-left mt-7 mb-7 md:w-[60%] w-[90%]  font-serif">
              Knowledge paves the way for the long-term benefits of yoga! Read these articles and learn about the core of yoga.
              </h6>
              {/* <button className="bg-[#6D5302] text-[#EACA57] rounded-full py-3 px-5 text-base font-normal hover:bg-[#D7AB0F]">
                Try 14 days for free
              </button> */}
            </div>
          </div>
          <div className='flex bg-[#fdf9e7] z-10'>
            <ArticlesTabs onArticleClick={handleArticleClick} handleTabChange={handleTabChange} />

          </div>
        </>
      {/* )} */}
    </div>
  );
};

export default Articles;
