import { useYogaPosesDeatil } from '../../../api/hooks/response';
import Loader from '../../../components/Loader/Loader';
import ArticlesDetails from '../../Articles/components/ArticlesDetails';
import VideoGalleryDetails from '../../VideoGallery/components/VideoGalleryDetails'
import React, { useState, useEffect } from 'react';
import leftBgLine from '../../../components/images/left_bg_line.png';
import DOMPurify from 'dompurify';
import { useParams, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import MetaTags from '../../../components/MetaTag';

const HtmlRenderer = ({ htmlContent, styleType, isList }) => {

    const sanitizeHtml = (html) => {
        return DOMPurify.sanitize(html);
    };

    const applyListStyles = (html) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        const uls = tempDiv.querySelectorAll('ul');
        const ols = tempDiv.querySelectorAll('ol');

        uls.forEach((ul) => {
            if (isList) {
                ul.classList.add('list-disc', 'pl-5', 'pr-5', 'grid', 'grid-cols-1', 'gap-2', 'md:gap-x-20', 'md:gap-y-0', 'md:grid-cols-2');
            } else {
                ul.classList.add('list-disc', 'pl-5');
            }
        });

        ols.forEach((ol) => {
            ol.classList.add('list-decimal', 'pl-5');
        });
        return tempDiv.innerHTML;
    };

    const processedHtml = applyListStyles(sanitizeHtml(htmlContent));

    const getStyleClass = () => {
        switch (styleType) {
            case 'short':
                return 'text-medium font-medium text-text-primary';
            case 'long':
                return 'text-large font-bold text-secondary w-[90%]';
            case 'list':
                return 'text-text-highlight font-medium pr-10';
            case 'longDes':
                return 'text-text-highlight text-large font-bold pr-10';
            default:
                return 'text-base text-primary';
        }
    };

    return (
        <div
            className={getStyleClass()}
            dangerouslySetInnerHTML={{ __html: processedHtml }}
        />
    );
};


const LearnYogaPosesDetail = ({ pose, onClose }) => {
    const { slug, id } = useParams();
    const { yogaPoses, loading, error } = useYogaPosesDeatil(id);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    console.log(yogaPoses, ",,,,,,,,,,,,,,,,,,,,,,,,,,")

    console.log(slug, id, "...")

    const base_url = 'https://yogsync.com/api';

    // const fetchCategories = async () => {
    //     try {
    //       const response = await axios.get(`${base_url}/user/yogapose/get/${id}`);
    //       if (response.data.status_code === 200) {
    //         const fetchedCategories = response.data;
    //         console.log(response.data.data)
    //         SetArticle(response.data.data)
    //       }
    //     } catch (error) {
    //       console.error('Error fetching categories', error);
    //     }
    //   };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const extractVideoId = (url) => {
        const match = url.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:embed\/|watch\?v=|v\/)?([^"&?\/\s]{11})/);
        return match ? match[1] : '';
    };

    const handleArticleClick = (article) => {
        setSelectedArticle(article);
    };

    const handleVideoClick = (video) => {

        setSelectedVideo(video);
    };

    if (loading) return <Loader />;
    if (error) return <div>Error loading poses.</div>;

    if (selectedVideo) {
        return (
            <VideoGalleryDetails
                video={selectedVideo}
                onClose={() => setSelectedVideo(null)}
                activeTab="VideoGallery"
            />
        );
    }

    if (selectedArticle) {
        return (
            <ArticlesDetails
                article={selectedArticle}
                onClose={() => setSelectedArticle(null)}
                activeTab="Articles"
            />
        );
    }
    const createSlug = (title) => {
        return title
            .toLowerCase()         // Convert to lowercase
            .replace(/\s+/g, '-')  // Replace spaces with hyphens
            .replace(/[^\w\-]+/g, '') // Remove non-alphanumeric characters except hyphen
            .replace(/\-\-+/g, '-')  // Replace multiple hyphens with a single hyphen
            .trim(); // Remove leading/trailing hyphens
    };
    function cleanMetaTag(metaTag) {

        let cleanedMetaTag = metaTag
          .replace(/\\\"/g, '')
          .replace(/\\\\/g, '')
          .replace(/\\+/g, '')
          .replace(/"/g,'');
    
        // Optional: Check the cleaned string to see if it contains valid HTML
        console.log("Cleaned Meta Tag: ", cleanedMetaTag);
    
        return cleanedMetaTag;
      }
    
      const cleanedMetaTag = cleanMetaTag(yogaPoses.meta_tag);

    return (
        <div className="article-detail-view bg-white my-10 mx-1  md:mx-10 mt-10" id="editor">

            <div className='flex flex-row gap-5 relative mt-5 '>
                <Link
                    className="bg-gradient-to-r from-[#F8EECC] to-[#EACA57] border border-[#111]   rounded-3xl py-5 px-20 text-sm text-black font-normal text-center font-poppins hover:bg-[#D7AB0F] cursor-pointer hover:underline  "
                   to="/learn-yoga-poses"
                >
                    Back to Poses
                </Link>
            </div>
            <MetaTags metaTags={cleanedMetaTag} />

            {/* <div className="flex flex-col text-center md:text-left gap-5  md:justify-center w-[100%] mt-10  rounded-lg md:rounded-none md:bg-transparent md:w-[100%]">
                <h2 className={`text-6xl font-bold text-black `}>{yogaPoses.name}</h2>
                <HtmlRenderer htmlContent={yogaPoses.short_description} styleType="long" />
            </div> */}

            <div className='flex flex-col gap-10'>
                <div className='flex flex-row flex-wrap md:flex-nowrap'>
                    <div className='flex flex-col w-full md:w-[60%]'>
                        <div className="flex flex-col text-center md:text-left gap-5  md:justify-center w-[100%] mt-10 mb-10 rounded-lg md:rounded-none md:bg-transparent md:w-[100%]">
                            <h2 className={`text-6xl font-bold text-black `}>{yogaPoses.name}</h2>
                            <HtmlRenderer htmlContent={yogaPoses.short_description} styleType="long" />
                        </div>
                        <div className='flex flex-row gap-5 md:gap-10 flex-wrap md:flex-nowrap items-end md:mt-[85px]'>
                            <div className="bg-gradient-to-r w-[100%] md:w-[50%] from-[#F8EECC] to-[#EACA57] h-[15vh] rounded-2xl border border-black  mb-4 flex flex-col gap-5 text-center justify-center items-center  ">
                                <h3 className="text-2xl font-bold text-black mb-1">Pose Type</h3>
                                <p className="text-[#101010]">{yogaPoses.pose_type}</p>
                            </div>


                            <div className="bg-gradient-to-r from-[#F8EECC] to-[#EACA57] rounded-2xl border h-[15vh] border-black   mb-4 flex flex-col gap-5 text-center justify-center items-center w-[100%] md:w-[50%]">
                                <h3 className="text-2xl font-bold text-black mb-1">Sanskrit Meaning</h3>
                                <p className="text-[#101010]">{yogaPoses.sanskrit_meaning}</p>
                            </div>
                            {/* <div className='flex  flex-1 ml-20 bg-red-900 items-end justify-end'>
                    <img
                        src={`https://www.certifit.in/yogsync/${yogaPoses.image}`}
                        alt={yogaPoses.name}
                        // className="w-[100vw] h-[40vh] md:w-[40vw] md:h-[80vh]  object-cover  rounded-full md:rounded-full md:bg-transparent md:mr-50 "
                        className='w-[100vw] h-[40vh] md:w-[30vw] md:h-[60vh]    rounded-full md:rounded-full md:bg-transparent  bg-red-900'
                    />
                        </div>
                    */}
                        </div>
                    </div>
                    <div className='flex md:-mt-20 w-full md:w-[40%]'>
                        <img
                            src={`https://yogsync.com/api/${yogaPoses.image}`}
                            alt={yogaPoses.name}
                            // className="w-[100vw] h-[40vh] md:w-[40vw] md:h-[80vh]  object-cover  rounded-full md:rounded-full md:bg-transparent md:mr-50 "
                            className='w-[100vw] h-[40vh] md:w-[30vw] md:h-[60vh]    rounded-full md:rounded-full md:bg-transparent  bg-red-900'
                        />
                    </div>

                </div>
                <div className='flex flex-row flex-wrap md:flex-nowrap gap-10 md:gap-10 w-70 md:-mt-[30px]'>
                    <div className="bg-gradient-to-r w-[100%] md:w-[50vw] from-[#F8EECC] to-[#EACA57] h-auto rounded-2xl  min-h-[20vh] border border-black  p-8  mb-1 flex flex-col gap-5">
                        <h3 className="text-2xl font-bold text-black mb-1">Benefits</h3>
                        <ul >
                            <HtmlRenderer htmlContent={yogaPoses.benefits} styleType="list" />
                        </ul>
                    </div>

                    <div className="bg-gradient-to-r from-[#F8EECC] md:w-[50vw] to-[#EACA57]  w-[100%] h-auto min-h-[20vh] rounded-2xl border border-black p-8 mb-1 flex flex-col gap-5">
                        <h3 className="text-2xl font-bold text-black mb-1">Targets</h3>
                        <ul >
                            <HtmlRenderer htmlContent={yogaPoses.targets} styleType="list" isList={true} />
                        </ul>

                    </div>

                </div>
            </div>
            <div className='flex flex-col mt-20 gap-10'>
                <div className='flex flex-col gap-5 border rounded-lg border-[#fcf2cf] z-10 py-10 px-5 md:px-10 md:mx-5'>
              
                    <p className="text-black text-lg font-medium text-center md:text-start  lg:text-start"><HtmlRenderer htmlContent={yogaPoses.long_description} styleType="longDes" /></p>
                </div>
                <div className='flex flex-col gap-5 border rounded-lg border-[#fcf2cf] z-10 py-10 px-5 md:px-10 md:mx-5'>
                    <h3 className='text-[60px] text-[#D7AB0F] font-bold' >Step-by-Step Guide</h3>
                    <h6 className='text-3xl text-black font-bold'>Here is the detailed step-by-step process for performing {yogaPoses.sanskritMeaning}:</h6>
                    <ul className='text-xl text-black font-normal list-disc'>
                        <HtmlRenderer htmlContent={yogaPoses.guidance} styleType="short" />
                    </ul>
                </div>
                <div className='flex flex-col gap-5 mt-15 border rounded-lg border-[#fcf2cf] z-10 py-10 px-5 md:px-10 md:mx-5'>
                    <h3 className='text-[60px] text-[#D7AB0F] font-bold mb-5'>Things You Need to Keep in Mind</h3>
                    <ul className='text-xl text-black font-normal list-disc'>
                        <HtmlRenderer htmlContent={yogaPoses.things_keep_in_mind} styleType="short" />
                    </ul>
                </div>
            </div>
            <div className='my-10 w-[100%] flex flex-col'>
                {yogaPoses.articles.length > 0 && (
                    <div className='flex flex-col gap-10 '>
                        <p className='text-[#D7AB0F] text-3xl font-bold text-start mt-10 '>Related Articles</p>
                        {yogaPoses.articles.map((item, index) => (
                            <Link
                                key={index}
                                className='flex flex-row gap-5 border rounded-lg border-[#fcf2cf]  py-10 px-5 md:py-0 lg:py-0 md:px-0 lg:px-0 cursor-pointer flex-wrap md:flex-nowrap lg:flex-nowrap'
                                // onClick={() => handleArticleClick(item)}
                                to={{
                                    pathname: `/articles/${item.id}/${createSlug(item.title)}`,
                                    // state: item , 
                                }}
                            >
                                <div className='w-[100%] md:w-[40%] lg:w-[40%] items-center justify-center md:justify-start lg:justify-start flex'>
                                    <img src={`https://yogsync.com/api/${item.image}`} alt={item.title} className='w-[100%] h-auto rounded-l-lg' />
                                </div>
                                <div className='flex flex-col gap-2 w-[100%] md:w-[60%] lg:w-[60%] justify-center px-10'>
                                    {/* <p className="text-[#D7AB0F] text-4xl font-extrabold text-center md:text-start  lg:text-start ">{item.title}</p>
                                    <p className="text-black text-xl font-bold text-center md:text-start  lg:text-start">{item.short_description}</p> */}
                                    {/* <p className="text-black text-lg font-medium text-center md:text-start  lg:text-start"><HtmlRenderer htmlContent={item.long_description} styleType="short" /></p> */}
                                    <p className="text-[#D7AB0F] text-4xl font-extrabold text-center md:text-start  lg:text-start "> <HtmlRenderer htmlContent={item.title} styleType="long" /></p>
                                    <p className="text-black text-xl font-bold text-center md:text-start  lg:text-start"> <HtmlRenderer htmlContent={item.short_description} styleType="short" /></p>
                                    {/* <p className="text-black text-lg font-medium text-center md:text-start  lg:text-start"> <HtmlRenderer htmlContent={item.long_description} styleType="short" /></p> */}
                                </div>
                            </Link>
                        ))}
                    </div>
                )}

                {yogaPoses.videos.length > 0 && (
                    <div className='flex flex-col gap-10  '>
                        <p className='text-[#D7AB0F] text-3xl font-bold text-start mt-10 '>Related Videos</p>
                        {yogaPoses.videos.map((item, index) => {
                            const videoId = extractVideoId(item.url);
                            return (
                                <div key={index} className='flex flex-row gap-5 border rounded-lg border-[#fcf2cf]  py-10 px-5 md:py-0 lg:py-0 md:px-0 lg:px-0 cursor-pointer flex-wrap md:flex-nowrap lg:flex-nowrap' onClick={() => handleVideoClick(item)}>
                                    <div className='w-[100%] md:w-[40%] lg:w-[40%] items-center justify-center md:justify-start lg:justify-start flex'>
                                        {videoId ? (
                                            <div className="flex relative w-[100%] h-auto ">
                                                <img
                                                    src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
                                                    alt="YouTube Video Thumbnail"
                                                    className="cursor-pointer w-full h-auto rounded-l-lg"
                                                />
                                                <img
                                                    src={require('../../../components/images/play.png')}
                                                    className="w-[20px] h-[20px] md:w-[30px] lg:w-[30px] md:h-[30px] lg:h-[30px] absolute top-[40%] left-[40%] md:top-[50%] lg:top-[50%] md:left-[50%] lg:left-[50%]"
                                                    alt="Play"
                                                />
                                            </div>
                                        ) : (
                                            <div className="text-red-500">Thumbnail not available</div>
                                        )}
                                    </div>
                                    <div className='flex flex-col gap-2 w-[100%] md:w-[60%] lg:w-[60%] justify-center px-10 '>
                                        <p className="text-[#D7AB0F] text-4xl font-extrabold text-center md:text-start  lg:text-start mt-10">{item.title}</p>
                                        {/* <p className="text-black text-lg font-medium text-center md:text-start  lg:text-start">{item.description}</p> */}

                                        <p className="text-black text-lg font-medium text-center md:text-start  lg:text-start"><HtmlRenderer htmlContent={item.description} styleType="short" /></p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}

                <div className='my-10 w-[90%] flex flex-col items-center justify-center'>
                    <h4 className="text-[#D7AB0F] text-2xl font-semibold text-start mt-10">Share Pose</h4>
                    <div className='flex flex-row gap-8 mt-5'>
                        <a
                            href="https://www.facebook.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Facebook"
                        >
                            <img src={require('../../../components/images/facebook_gray.png')} className='w-[25px] h-[25px] object-cover filter brightness-0 opacity-100' alt="Facebook" />
                        </a>
                        <a
                            href="https://twitter.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Twitter"
                        >
                            <img src={require('../../../components/images/twitter_gray.png')} className='w-[25px] h-[25px] object-cover filter brightness-0 opacity-100' alt="Twitter" />
                        </a>
                        <a
                            href="https://www.pinterest.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Pinterest"
                        >
                            <img src={require('../../../components/images/pintrust_gray.png')} className='w-[25px] h-[25px] object-cover filter brightness-0 opacity-100' alt="Pinterest" />
                        </a>
                        <a
                            href="https://wa.me/"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="WhatsApp"
                        >
                            <img src={require('../../../components/images/whatsapp_gray.png')} className='w-[25px] h-[25px] object-cover filter brightness-0 opacity-100' alt="WhatsApp" />
                        </a>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default LearnYogaPosesDetail;


{/* <div className="flex flex-col justify-center px-3 md:px-5 lg:px-5"> */ }
{/* <div className='flex flex-col md:flex-row-reverse gap-10 lg:flex-row-reverse'>
                    <div className='flex w-[100%] md:w-[50%] lg:w-[50%] items-center justify-center'>
                        <div className="flex relative w-[80%] h-auto">
                            <img
                                src={`https://www.certifit.in/yogsync/${yogaPoses.image}`}
                                className="w-full object-contain h-auto"
                                alt={yogaPoses.name}
                            />
                        </div>
                    </div>

                    <div className='flex flex-col gap-10 relative py-0 w-[100%] md:w-[50%] lg:w-[50%] justify-center'>
                        <div className='flex flex-row gap-5 relative'>
                            <button
                                className="bg-[#D7AB0F] rounded-[5px] py-3 px-4 text-sm text-black font-normal text-center border-none font-poppins hover:bg-[#D7AB0F] cursor-pointer hover:underline"
                                onClick={onClose}
                            >
                                Back to Poses
                            </button>
                        </div>

                        <p className="text-[#D7AB0F] text-3xl font-bold text-start">{yogaPoses.name}</p>
                        <p className="text-black text-lg font-normal text-start">{yogaPoses.short_description}</p>
                        <div className='flex flex-row gap-5 relative'>
                            <a
                                href="https://www.facebook.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Facebook"
                            >
                                <img src={require('../../../components/images/facebook_gray.png')} className='w-[25px] h-[25px] object-cover filter brightness-0 opacity-100' alt="Facebook" />
                            </a>
                            <a
                                href="https://twitter.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Twitter"
                            >
                                <img src={require('../../../components/images/twitter_gray.png')} className='w-[25px] h-[25px] object-cover filter brightness-0 opacity-100' alt="Twitter" />
                            </a>
                            <a
                                href="https://www.pinterest.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Pinterest"
                            >
                                <img src={require('../../../components/images/pintrust_gray.png')} className='w-[25px] h-[25px] object-cover filter brightness-0 opacity-100' alt="Pinterest" />
                            </a>
                            <a
                                href="https://wa.me/"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="WhatsApp"
                            >
                                <img src={require('../../../components/images/whatsapp_gray.png')} className='w-[25px] h-[25px] object-cover filter brightness-0 opacity-100' alt="WhatsApp" />
                            </a>
                        </div>
                    </div>
                </div> */}


//     <div className='flex flex-row gap-5 relative mt-5  md:-mb-20'>
//     <button
//         className="bg-gradient-to-r from-[#F8EECC] to-[#EACA57] border border-[#111] mt-10  rounded-[33px] py-5 px-20 text-sm text-black font-normal text-center font-poppins hover:bg-[#D7AB0F] cursor-pointer hover:underline ml-10  "
//         onClick={onClose}
//     >
//         Back to Poses
//     </button>
// </div>
// <div className="flex flex-col  mt-0">
//     <div
//         className={`flex flex-wrap md:flex-nowrap lg:flex-nowrap flex-row-reverse w-[80vw] h-[60vh] items-center `}
//     // style={{
//     //     backgroundImage: isMobile
//     //         ? 'none'
//     //         : `url(${leftBgLine})`,
//     //     backgroundPosition: 'center',
//     //     backgroundSize: 'contain',
//     //     backgroundRepeat: 'no-repeat',
//     // }}

//     >
//         {/* <img
//             src={`https://www.certifit.in/yogsync/${yogaPoses.image}`}
//             alt={yogaPoses.name}
//             className="w-[100vw] h-[40vh] md:w-[25vw] md:h-[50vh]  object-cover  rounded-full md:rounded-full md:bg-transparent md:mr-5"
//         /> */}

//         <div className="flex flex-col text-center md:text-left mt-5 pt-10 md:mt-0 md:justify-center w-[100%] md:h-[60vh] md:ml-10  rounded-lg  mb-10 md:rounded-none md:bg-transparent md:w-[100%]">
//             <h2 className={`text-4xl font-bold text-[#D7AB0F] `}>{yogaPoses.name}</h2>
//             <HtmlRenderer htmlContent={yogaPoses.short_description} styleType="long" />
//         </div>
//     </div>

// </div>
