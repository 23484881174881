export const articles = [
  {
    id: 1,
    title: 'Holiday Yoga Toolkit',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ',
    image: require('../src/components/images/Latest_Article_1.png'),
  },
  {
    id: 2,
    title: 'What is Hatha Yoga?',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod',
    image: require('../src/components/images/Latest_Article_2.png'),
  },
  {
    id: 3,
    title: 'The benefits of Yin Yoga',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod read more',
    image: require('../src/components/images/Latest_Article_3.png'),
  },
  {
    id: 4,
    title: 'Mastering Vinyasa Flow',
    description: 'Discover the dynamic sequence of postures that synchronize breath with movement, enhancing flexibility and strength.',
    image: require('../src/components/images/Latest_Article_4.png'),
  },
  {
    id: 5,
    title: 'Understanding Ashtanga Yoga',
    description: 'Explore the eight-limbed path of Ashtanga Yoga and its benefits for mental and physical well-being.',
    image: require('../src/components/images/Latest_Article_5.png'),
  },
  {
    id: 6,
    title: 'Breath Control Techniques',
    description: 'Learn effective breath control techniques to enhance your yoga practice and improve relaxation.',
    image: require('../src/components/images/Latest_Article_6.png'),
  },
  {
    id: 7,
    title: 'Meditation Practices for Beginners',
    description: 'A guide to meditation practices for beginners to achieve mental clarity and peace.',
    image: require('../src/components/images/Latest_Article_7.png'),
  },
];


export const yogaGuide = [
  {
    id: 1,
    title: 'Beginner Level',
    subtitle: 'Easy Pose - Sukhasana',
    description: 'Lay the foundation of your body awareness and sense of calmness with beginner-level yoga poses gradually and easily.',
    image: require('../src/components/images/yoga_img1.png'),
    poseType: 'Seated',
    sanskritMeaning: 'Sukhasana',
    benefits: [
      'Promotes groundedness and calm',
      'Improves posture',
      'Opens the hips',
      'Promotes groundedness and calm',
      'Promotes groundedness and calm',
    ],
    targets: [
      'Hips',
      'Lower Back',
      'Lower Back',
      'Lower Back',
      'Lower Back',
    ],
    stepByStepGuide: [
      'Begin by sitting on the floor with your legs comfortably crossed in front of you. Ensure that your spine is straight and your shoulders are relaxed, with your hands resting on your knees. Close your eyes gently and take a few deep breaths, inhaling through your nose and exhaling through your mouth. This helps in grounding your body and calming your mind as you prepare for the pose.',
      'As you settle into the pose, focus on aligning your spine by imagining a string pulling the top of your head towards the ceiling. Keep your hips level and evenly aligned with your legs. Adjust your seated position if necessary to ensure maximum comfort and balance. Continue breathing deeply, allowing each inhalation to lengthen your spine and each exhalation to release any tension.',
      'Place your hands on your knees with your palms facing up or down, depending on your comfort. Engage your core muscles slightly to support your lower back and maintain an upright posture. Ensure that your shoulders remain relaxed and away from your ears throughout the pose. This will help to maintain a sense of stability and alignment as you continue to breathe deeply and evenly.',
      'Focus your attention inward, letting go of any external distractions. Visualize a calm and centered state of mind as you maintain the pose. If your mind begins to wander, gently bring your focus back to your breath and your posture. Continue to sit in Sukhasana for several minutes, or as long as you feel comfortable, allowing yourself to experience the full benefits of the pose.'
    ],
    thingsToKeepInMind: [
      {
        title: 'Ensure your back is straight.',
        description: 'While practicing Sukhasana, it is crucial to keep your back straight to avoid any strain on your spine. Sit upright with your spine elongated, avoiding any slouching or hunching. A straight back promotes proper alignment and allows for deeper breathing. If you find it difficult to maintain an upright posture, consider using a cushion or folded blanket underneath you for added support and comfort.'
      },
      {
        title: 'Avoid hunching your shoulders.',
        description: 'To prevent unnecessary tension in your shoulders, keep them relaxed and away from your ears. Hunching the shoulders can lead to discomfort and disrupt your focus during the pose. Make a conscious effort to draw your shoulder blades down and back, opening up your chest and creating a more comfortable seating position. This adjustment will enhance your overall practice and help you maintain a serene and balanced state.'
      }
    ]
  },
  {
    id: 2,
    title: 'Beginner - Intermediate',
    subtitle: 'Mountain Pose - Parwatasana',
    description: 'Take advantage of a deeper understanding of yoga poses with beginner-intermediate knowledge of the practice. ',
    image: require('../src/components/images/yoga_img2.png'),
    poseType: 'Standing',
    sanskritMeaning: 'Parwatasana',
    benefits: [
      'Strengthens the back',
      'Improves posture',
      'Brings a sense of stillness'
    ],
    targets: [
      'Back',
      'Legs'
    ],
    stepByStepGuide: [
      'Stand with your feet hip-width apart and your arms by your sides. Distribute your weight evenly across both feet, grounding yourself firmly into the floor. Engage your core muscles to support your spine, and keep your legs strong and straight. Relax your shoulders, allowing them to drift away from your ears as you prepare for the pose.',
      'Inhale deeply and slowly raise your arms overhead, keeping them parallel to each other and in line with your feet. Ensure that your shoulders remain relaxed and your arms are straight but not rigid. Gaze forward or slightly upward to maintain balance and alignment. Hold the pose for a few breaths, feeling the stretch through your arms and torso.',
      'As you exhale, focus on lengthening your spine and rooting your feet into the ground. Engage your thigh muscles to support your legs and prevent any swaying or wobbling. Keep your body aligned and centered, avoiding any excessive arching of the lower back. Continue to breathe deeply, maintaining your focus and composure in the pose.',
      'To exit the pose, slowly lower your arms back to your sides as you exhale. Take a moment to observe how your body feels and make any necessary adjustments. If desired, repeat the pose several times, paying attention to your alignment and breath with each repetition. This will help you build strength and stability in the pose.'
    ],
    thingsToKeepInMind: [
      {
        title: 'Keep your shoulders relaxed.',
        description: 'In Mountain Pose, it is important to keep your shoulders relaxed to avoid any strain or discomfort. Tension in the shoulders can affect your overall alignment and balance. To achieve this, actively draw your shoulder blades down your back and away from your ears. This adjustment will open your chest and promote better posture. Pay attention to how your shoulders feel throughout the pose and make any necessary adjustments to maintain relaxation.'
      },
      {
        title: 'Engage your core muscles.',
        description: 'Engaging your core muscles is essential for maintaining stability and proper alignment in Mountain Pose. A strong core supports your spine and helps you stay balanced. To engage your core, draw your navel towards your spine and tighten your abdominal muscles. This activation will help you remain steady and prevent any unnecessary strain on your back. Remember to keep your core engaged throughout the pose, ensuring a strong and supportive foundation.'
      }
    ]
  },
  {
    id: 3,
    title: 'Intermediate Level',
    subtitle: 'Bow Pose - Dhanurasana',
    description: 'Climb a step high and learn the complexities of yoga poses and inversions with intermediate-level practice.',
    image: require('../src/components/images/yoga_img3.png'),
    poseType: 'Prone',
    sanskritMeaning: 'Dhanurasana',
    benefits: [
      'Stretches the front of the body',
      'Builds strength and flexibility'
    ],
    targets: [
      'Chest',
      'Abdomen',
      'Thighs'
    ],
    stepByStepGuide: [
      'Lie flat on your stomach with your arms extended by your sides and your legs straight. Bend your knees and reach back to grasp your ankles with your hands. Ensure that your thighs remain close together and that your toes point away from your body. This positioning will set the foundation for the pose and help you achieve the desired bow shape.',
      'Inhale deeply and begin to lift your chest and legs off the ground, pressing your feet into your hands. Engage your back muscles to raise your torso and thighs higher. Keep your gaze forward and maintain a steady breath as you hold the position. This will enhance your flexibility and strength while stabilizing your body in the pose.',
      'Focus on maintaining an even distribution of weight across your abdomen and legs. Avoid putting excessive pressure on your lower back by keeping your core engaged and supporting your spine. Adjust the position of your legs and arms if necessary to ensure a balanced and effective stretch. Continue to breathe deeply and hold the pose for several breaths.',
      'To release the pose, exhale slowly and gently lower your chest and legs back to the floor. Release your ankles and relax your arms by your sides. Take a moment to observe the effects of the pose on your body and mind. Repeat the pose if desired, focusing on enhancing your strength and flexibility with each attempt.'
    ],
    thingsToKeepInMind: [
      {
        title: 'Keep your breathing steady.',
        description: 'During Bow Pose, maintaining steady and controlled breathing is crucial for maximizing the benefits and sustaining the pose. Breathe deeply and consistently through your nose, focusing on your inhalations and exhalations. This steady breath helps to relax your body and mind, enhancing your focus and stability. Avoid holding your breath, as it can increase tension and limit your flexibility. Practice deep, rhythmic breathing to support your posture and achieve greater ease in the pose.'
      },
      {
        title: 'Avoid straining your lower back.',
        description: 'To prevent strain on your lower back during Bow Pose, ensure that you engage your core muscles effectively. A strong core helps to support your spine and distribute the stretch evenly throughout your body. Avoid overarching your lower back and focus on lifting your chest and legs using your back muscles rather than relying solely on your lower back. If you experience discomfort, adjust your position or reduce the intensity of the pose to protect your lower back and maintain proper alignment.'
      }
    ]
  },
  {
    id: 4,
    title: 'Intermediate - Advanced',
    subtitle: 'Dance Pose - Natrajasana',
    description: "You’re just a step away from becoming an advanced-level yoga practitioner while managing your body's act, behavior, and flexibility.",
    image: require('../src/components/images/yoga_img4.png'),
    poseType: 'Standing',
    sanskritMeaning: 'Natrajasana',
    benefits: [
      'Improves balance',
      'Enhances concentration',
      'Increases flexibility'
    ],
    targets: [
      'Balance',
      'Legs',
      'Core'
    ],
    stepByStepGuide: [
      'Begin by standing on your left leg with your right leg bent at the knee and your foot lifted behind you. Reach back with your right hand to grasp your ankle, ensuring that your standing leg remains straight and firm. Engage your core muscles to support your balance as you prepare for the next step. Focus on grounding yourself through your standing leg and maintaining an upright posture.',
      'Slowly extend your right leg behind you, keeping your foot firmly held by your hand. Lift your chest and extend your left arm forward to help with balance. Ensure that your standing leg remains strong and your torso stays aligned. Continue to breathe deeply and evenly as you hold the pose, finding a focal point to help maintain your balance and concentration.',
      'As you hold the pose, concentrate on maintaining stability and avoiding any excessive swaying. Engage your core and back muscles to keep your torso steady. Adjust the position of your lifted leg if necessary to achieve a balanced alignment. Keep your gaze fixed on a point in front of you to enhance your focus and control.',
      'To release the pose, gently lower your right leg back to the ground and release your ankle. Take a moment to stabilize and balance yourself on your standing leg. Repeat the pose on the other side, following the same steps to ensure a balanced and symmetrical practice. Gradually build strength and flexibility as you practice Dance Pose regularly.'
    ],
    thingsToKeepInMind: [
      {
        title: 'Keep your core engaged.',
        description: 'Engaging your core is essential for maintaining balance and stability in Dance Pose. A strong core provides support and prevents you from wobbling or falling out of the pose. Focus on drawing your belly button towards your spine and keeping your abdominal muscles activated throughout the pose. This engagement helps you maintain proper alignment and enhances your overall balance. Regular practice will strengthen your core and improve your ability to hold this challenging pose.'
      },
      {
        title: 'Focus on a point to help with balance.',
        description: 'To enhance your balance in Dance Pose, it is helpful to focus on a fixed point in front of you. This visual focus helps stabilize your body and improves concentration. Choose a spot that is directly in your line of sight and maintain your gaze on it throughout the pose. Avoid looking around or shifting your focus, as this can disrupt your balance. With practice, you will develop greater control and poise, making it easier to hold the pose for longer periods.'
      }
    ]
  },
  {
    id: 5,
    title: 'Advanced Level',
    subtitle: 'Headstand Pose - Sirsasana',
    description: 'From self-awareness to a deeper understanding of yoga poses, learn everything about being the master of your body and mind. ',
    image: require('../src/components/images/yoga_img5.png'),
    poseType: 'Inverted',
    sanskritMeaning: 'Sirsasana',
    benefits: [
      'Strengthens the core',
      'Enhances focus',
      'Revitalizes the body'
    ],
    targets: [
      'Core',
      'Shoulders',
      'Arms'
    ],
    stepByStepGuide: [
      'Start by kneeling on the floor and placing the crown of your head on the mat. Interlace your fingers behind your head, creating a stable base. Carefully lift your legs off the ground, bringing them towards the ceiling. Ensure your core is engaged to support your spine and maintain alignment throughout the pose. Keep your gaze forward or slightly upward to help with balance.',
      'Once your legs are fully extended, focus on maintaining a straight line from your head to your feet. Engage your shoulder and arm muscles to support your weight and prevent any strain on your neck. Breathe deeply and steadily, maintaining your focus and concentration as you hold the pose. Adjust your positioning if necessary to ensure stability and comfort.',
      'Hold the pose for several breaths, gradually increasing the duration as you build strength and confidence. Avoid any excessive arching or straining, and keep your body aligned to prevent injury. If you feel any discomfort, gently lower your legs and come out of the pose. Take a moment to rest and recover before attempting the pose again.',
      'To exit the pose, slowly lower your legs back to the floor and release your head from the mat. Rest in a comfortable seated position and observe how your body feels after the inversion. Practice Headstand Pose regularly to improve your strength, focus, and overall well-being, while ensuring that you maintain proper technique and alignment.'
    ],
    thingsToKeepInMind: [
      {
        title: 'Keep your breathing steady.',
        description: 'During Headstand Pose, maintaining steady and controlled breathing is crucial for maximizing the benefits and sustaining the pose. Breathe deeply and consistently through your nose, focusing on your inhalations and exhalations. This steady breath helps to relax your body and mind, enhancing your focus and stability. Avoid holding your breath, as it can increase tension and limit your flexibility. Practice deep, rhythmic breathing to support your posture and achieve greater ease in the pose.'
      },
      {
        title: 'Avoid straining your neck and shoulders.',
        description: 'In Headstand Pose, it is essential to avoid straining your neck and shoulders to prevent injury and discomfort. Ensure that your weight is evenly distributed between your head, shoulders, and arms, with minimal pressure on your neck. Engage your core muscles to support your spine and maintain alignment. If you experience any pain or discomfort in your neck or shoulders, come out of the pose gently and make adjustments as needed to reduce strain and maintain proper form.'
      }
    ]
  }
];


export const expertPick = [
  {
    id: 1,
    title: '10 best yoga poses for tight hips',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod',
    image: require('../src/components/images/expert_pick1.png'),
  },
  {
    id: 2,
    title: 'Deal with difficult emotions',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod',
    image: require('../src/components/images/expert_pick2.png'),
  },
  {
    id: 3,
    title: 'Yoga poses for tight hamstrings',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmodLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ',
    image: require('../src/components/images/expert_pick3.png'),
  },
  {
    id: 4,
    title: '10 best yoga poses for tight hips',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod',
    image: require('../src/components/images/expert_pick1.png'),
  },

];

export const expertTeacher = [
  {
    id: 1,
    title: 'Kirsten Drooger',
    description: 'Yin Yoga',
    image: require('../src/components/images/Kirsten_Drooger.png'),
  },
  {
    id: 2,
    title: 'Katy Appleton',
    description: 'Hatha Yoga',
    image: require('../src/components/images/Katy_Appleton.png'),
  },
  {
    id: 3,
    title: 'Lisa Petersen',
    description: 'Meditation',
    image: require('../src/components/images/Lisa_Petersen.png'),
  },
  {
    id: 4,
    title: 'Adela Serrano ',
    description: 'Yin Yoga',
    image: require('../src/components/images/Adela_Serrano .png'),
  },

];

export const yogaPosesGuide = [
  {
    id: 1,
    title: 'Beginner',
    subtitle: 'EASY POSE - SUKHASANA',
    description: 'Sukhasana, or Easy Pose, is a simple seated position that promotes groundedness and calm. Ideal for beginners, it helps improve posture and opens the hips.',
    image: require('../src/components/images/yoga_img1.png'),
  },
  {
    id: 2,
    title: 'Beginner',
    subtitle: 'STAFF POSE / STICK POSE DANDASANA',
    description: 'Parwatasana, also known as Mountain Pose, strengthens the back, improves posture, and brings a sense of stillness. It is a great transition from beginner to intermediate levels.',
    image: require('../src/components/images/yoga_img2.png'),
  },
  {
    id: 3,
    title: 'Beginner',
    subtitle: 'MOUNTAIN POSE TADASANA',
    description: 'Dhanurasana, or Bow Pose, is an intermediate-level asana that stretches the entire front of the body, including the chest, abdomen, and thighs. It builds strength and flexibility.',
    image: require('../src/components/images/yoga_img3.png'),
  },
  {
    id: 4,
    title: 'Beginner - Intermediate',
    subtitle: 'PLANK POSE PHALANKASANA / KUMBHAKASANA',
    description: 'Natrajasana, also known as Dance Pose, is a graceful and challenging pose that improves balance, concentration, and flexibility, bridging the gap between intermediate and advanced levels.',
    image: require('../src/components/images/yoga_img4.png'),
  },
  {
    id: 5,
    title: 'Beginner - Advanced',
    subtitle: 'SEATED FORWARD FOLD PASCHIMOTTANASANA',
    description: 'Sirsasana, or Headstand Pose, is an advanced inversion that strengthens the core, enhances focus, and revitalizes the entire body. It requires both strength and mental discipline.',
    image: require('../src/components/images/yoga_img1.png'),
  },
  {
    id: 6,
    title: 'Beginner - Intermediate',
    subtitle: 'WARRIOR 1 POSE VIRABHADRASANA I',
    description: 'Sukhasana, or Easy Pose, is a simple seated position that promotes groundedness and calm. Ideal for beginners, it helps improve posture and opens the hips.',
    image: require('../src/components/images/yoga_img1.png'),
  },
  {
    id: 7,
    title: 'Beginner - Intermediate',
    subtitle: 'WARRIOR 2 POSE VIRABHADRASANA II',
    description: 'Parwatasana, also known as Mountain Pose, strengthens the back, improves posture, and brings a sense of stillness. It is a great transition from beginner to intermediate levels.',
    image: require('../src/components/images/yoga_img2.png'),
  },
  {
    id: 8,
    title: 'Beginner - Intermediate',
    subtitle: 'TREE POSE VRKSASANA',
    description: 'Dhanurasana, or Bow Pose, is an intermediate-level asana that stretches the entire front of the body, including the chest, abdomen, and thighs. It builds strength and flexibility.',
    image: require('../src/components/images/yoga_img3.png'),
  },
  {
    id: 9,
    title: 'Intermediate',
    subtitle: 'GARLAND POSE MALASANA',
    description: 'Natrajasana, also known as Dance Pose, is a graceful and challenging pose that improves balance, concentration, and flexibility, bridging the gap between intermediate and advanced levels.',
    image: require('../src/components/images/yoga_img4.png'),
  },
  {
    id: 10,
    title: 'Intermediate',
    subtitle: 'UPWARD FACING DOG POSE URDHVA MUKHA SVANASANA',
    description: 'Sirsasana, or Headstand Pose, is an advanced inversion that strengthens the core, enhances focus, and revitalizes the entire body. It requires both strength and mental discipline.',
    image: require('../src/components/images/yoga_img1.png'),
  },
  {
    id: 11,
    title: 'Intermediate',
    subtitle: 'DOWNWARD FACING DOG POSE ADHO MUKHA SVANASANA',
    description: 'Sirsasana, or Headstand Pose, is an advanced inversion that strengthens the core, enhances focus, and revitalizes the entire body. It requires both strength and mental discipline.',
    image: require('../src/components/images/yoga_img1.png'),
  },
  {
    id: 12,
    title: 'Intermediate - Advanced',
    subtitle: 'EXTENDED TRIANGLE POSE UTTHITA TRIKONASANA',
    description: 'Sirsasana, or Headstand Pose, is an advanced inversion that strengthens the core, enhances focus, and revitalizes the entire body. It requires both strength and mental discipline.',
    image: require('../src/components/images/yoga_img1.png'),
  },
];