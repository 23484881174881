import { useEffect, useState } from 'react';
import { fetchLatestArticles,fetchAllArticles, fetchVideo,fetchAllYogaPoses, fetchAllYogaDetails,fetchExpertApprovedDetails } from '../services/services';

export const useLatestArticles = () => {
  const [latestArticles, setLatestArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setLoading(true);
        const data = await fetchLatestArticles();
        setLatestArticles(data.data);
      } catch (error) { 
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  return { latestArticles, loading, error };
};


export const useAllArticles = () => {
  const [alltArticles, setAllArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setLoading(true);
        const data = await fetchAllArticles();
        setAllArticles(data.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  return { alltArticles, loading, error };
};

export const useVideo = () => {
  const [video, setVideo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAllVideo = async () => {
      try {
        setLoading(true);
        const data = await fetchVideo();
        setVideo(data.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllVideo();
  }, []);

  return { video, loading, error };
};

export const useYogaPoses = () => {
  const [yogaPoses, setYogaPoses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
      const fetchPoses = async () => {
          try {
              setLoading(true);
              const data = await fetchAllYogaPoses(); 
              setYogaPoses(data.data);
          } catch (error) {
              setError(error);
              console.error(error);
          } finally {
              setLoading(false);
          }
      };

      fetchPoses();
  }, []);

  return { yogaPoses, loading, error };
};

export const useYogaPosesDeatil = (pose_id) => {
  const [yogaPoses, setYogaPoses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
      const fetchPosesDetail = async () => {
          try {
              setLoading(true);
              const data = await fetchAllYogaDetails(pose_id); 
              setYogaPoses(data.data);
          } catch (error) {
              setError(error);
              console.error(error);
          } finally {
              setLoading(false);
          }
      };

      fetchPosesDetail();
  }, []);

  return { yogaPoses, loading, error };
};

export const useExpertApprovedArticles = () => {
  const [allexpert, setAllExpert] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchExpert = async () => {
      try {
        setLoading(true);
        const data = await fetchExpertApprovedDetails();
        setAllExpert(data.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchExpert();
  }, []);

  return { allexpert, loading, error };
};