// import React, { useState, useEffect } from 'react';
// import { useAllArticles } from '../../../api/hooks/response';
// import Loader from '../../../components/Loader/Loader';
// import DOMPurify from 'dompurify';

// const HtmlRenderer = ({ htmlContent, styleType, isList }) => {
//   const sanitizeHtml = (html) => {
//     return DOMPurify.sanitize(html);
//   };

//   const applyListStyles = (html) => {
//     const tempDiv = document.createElement('div');
//     tempDiv.innerHTML = html;

//     const uls = tempDiv.querySelectorAll('ul');
//     const ols = tempDiv.querySelectorAll('ol');

//     uls.forEach((ul) => {
//       if (isList) {
//         ul.classList.add('list-disc', 'pl-5', 'pr-5', 'grid', 'grid-cols-1', 'gap-2', 'md:gap-x-20', 'md:gap-y-0', 'md:grid-cols-2');
//       } else {
//         ul.classList.add('list-disc', 'pl-5');
//       }
//     });

//     ols.forEach((ol) => {
//       ol.classList.add('list-decimal', 'pl-5');
//     });

//     return tempDiv.innerHTML;
//   };

//   const processedHtml = applyListStyles(sanitizeHtml(htmlContent));

//   const getStyleClass = () => {
//     switch (styleType) {
//       case 'short':
//         return 'text-medium font-medium text-black'; // Apply specific Tailwind classes
//       case 'long':
//         return 'text-large font-medium text-[#D7AB0F] w-[90%]'; // Apply different Tailwind classes
//       case 'list':
//         return 'text-text-highlight font-medium pr-10'; // Style for lists
//       default:
//         return 'text-base text-text-primary'; // Default styling
//     }
//   };

//   return (
//     <div
//       className={getStyleClass()}
//       dangerouslySetInnerHTML={{ __html: processedHtml }}
//     />
//   );
// };

// const ArticlesTabs = ({ onArticleClick, handleTabChange }) => {
//   const [activeTab, setActiveTab] = useState('All');
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 10;

//   const { alltArticles, loading: loadingAll, error: errorAll } = useAllArticles();

//   const data = activeTab === 'All'
//     ? alltArticles.flatMap(category => category.articles)
//     : alltArticles.find(category => category.name === activeTab)?.articles || [];

//   const totalPages = data ? Math.ceil(data.length / itemsPerPage) : 0;
//   const paginatedData = data ? data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) : [];

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };

//   const handleNextPage = () => {
//     if (currentPage < totalPages) {
//       setCurrentPage(currentPage + 1);
//     }
//   };

//   const handlePreviousPage = () => {
//     if (currentPage > 1) {
//       setCurrentPage(currentPage - 1);
//     }
//   };

//   useEffect(() => {
//     setCurrentPage(1);
//   }, [activeTab]);

//   return (
//     <div className="tab-container bg-[#fdf9e7] p-6 w-full mt-10">
//       <div className="tab-header flex mb-10 overflow-y-scroll md:overflow-auto lg:overflow-auto">
//         {['All', 'Practices', 'Meditation', 'Anatomy', 'Philosophy', 'Wellbeing'].map((tab) => (
//           <button
//             key={tab}
//             onClick={() => setActiveTab(tab)}
//             className={`tab-button py-2 px-4 text-lg font-semibold ${activeTab === tab ? 'text-[#D7AB0F] border-b-2 border-[#D7AB0F]' : 'text-black'
//               }`}

//           >
//             {tab}
//           </button>
//         ))}
//       </div>

//       {loadingAll ? (
//         <Loader />
//       ) : errorAll ? (
//         <div>Error loading articles</div>
//       ) : (
//         <div className="tab-content ">
//           <div className="special-images grid grid-cols-12 gap-5 mb-20">
//             {paginatedData.slice(0, 2).map((item, index) => (
//               <div
//                 key={index}
//                 className={`col-span-12 bg-[#fdf9e7] rounded-lg shadow-md ${index === 0 ? 'md:col-span-7' : 'md:col-span-5'} article-item overflow-hidden transition-transform duration-500  hover:scale-105`}
//                 onClick={() => {
//                   onArticleClick(item);
//                   handleTabChange(activeTab);
//                 }}

//               >
//                 <div className="image-container relative overflow-hidden ">
//                   <img
//                     src={("https://www.certifit.in/yogsync/") + (item.image)}
//                     className=" object-cover w-full h-[300px] rounded-t-lg"
//                     alt={item.title}
//                   />
//                   <div className="p-5 ">
//                     {/* <h2 className="text-[#D7AB0F] text-xl text-left font-semibold transition-colors duration-500 ">{item.title}</h2> */}
//                     <HtmlRenderer htmlContent={item.title} styleType="long" />
//                     <HtmlRenderer htmlContent={item.short_description} styleType="short" />
//                     {/* <p className="text-black text-sm text-left mt-1 transition-colors duration-500 ">{item.short_description}</p> */}
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//           <div className="remaining-images grid grid-cols-2 md:grid-cols-4 gap-5">
//             {paginatedData.slice(2).map((item, index) => (
//               <div
//                 key={index}
//                 className="article-item bg-[#fdf9e7]  rounded-lg shadow-md flex flex-col mb-20"
//                 onClick={() => {
//                   onArticleClick(item);
//                   handleTabChange(activeTab);
//                 }}
//               >
//                 <div className="image-container relative overflow-hidden transition-transform duration-500 hover:scale-105">
//                   <img
//                     src={("https://www.certifit.in/yogsync/") + (item.image)}
//                     className=" object-cover w-full h-[200px] rounded-t-lg "
//                     alt={item.title}
//                   />
//                   <div className="p-5">
//                     <HtmlRenderer htmlContent={item.title} styleType="long" />
//                     <HtmlRenderer htmlContent={item.short_description} styleType="short" />
//                     {/* <h2 className="text-black text-lg text-left font-semibold transition-colors duration-500 ">{item.title}</h2>
//                     <p className="text-black text-sm text-left mt-1 transition-colors duration-500">{item.short_description}</p> */}
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//           {totalPages > 1 && (
//             <div className="pagination flex justify-center items-center mt-6">
//               <img src={require('../../../components/images/left_arrow.png')} onClick={handlePreviousPage} disabled={currentPage === 1} className="mx-2  disabled:opacity-50 h-[15px] w-[15px] filter brightness-0 opacity-90" alt="Previous" />
//               {[...Array(totalPages)].map((_, i) => (
//                 <button
//                   key={i}
//                   onClick={() => handlePageChange(i + 1)}
//                   className={`mx-1 px-3 py-1 rounded ${currentPage === i + 1 ? 'bg-[#D7AB0F] text-black' : 'hover:bg-[#775f08]'}`}
//                 >
//                   {i + 1}
//                 </button>
//               ))}
//               <img src={require('../../../components/images/right_arrow.png')} onClick={handleNextPage} disabled={currentPage === totalPages} className="mx-2  disabled:opacity-50 h-[15px] w-[15px] filter brightness-0 opacity-90" alt="Next" />
//             </div>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

// export default ArticlesTabs;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loader from '../../../components/Loader/Loader';
import DOMPurify from 'dompurify';
import { Link } from 'react-router-dom';

const HtmlRenderer = ({ htmlContent, styleType, isList }) => {
  const sanitizeHtml = (html) => {
    return DOMPurify.sanitize(html);
  };

  const applyListStyles = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    const uls = tempDiv.querySelectorAll('ul');
    const ols = tempDiv.querySelectorAll('ol');

    uls.forEach((ul) => {
      if (isList) {
        ul.classList.add('list-disc', 'pl-5', 'pr-5', 'grid', 'grid-cols-1', 'gap-2', 'md:gap-x-20', 'md:gap-y-0', 'md:grid-cols-2');
      } else {
        ul.classList.add('list-disc', 'pl-5');
      }
    });

    ols.forEach((ol) => {
      ol.classList.add('list-decimal', 'pl-5');
    });

    return tempDiv.innerHTML;
  };

  const processedHtml = applyListStyles(sanitizeHtml(htmlContent));

  const getStyleClass = () => {
    switch (styleType) {
      case 'short':
        return 'text-medium font-medium text-black';
      case 'long':
        return 'text-large font-medium text-[#D7AB0F] w-[90%]';
      case 'list':
        return 'text-text-highlight font-medium pr-10';
      default:
        return 'text-base text-text-primary';
    }
  };

  return (
    <div
      className={getStyleClass()}
      dangerouslySetInnerHTML={{ __html: processedHtml }}
    />
  );
};

const ArticlesTabs = ({ onArticleClick, handleTabChange }) => {
  const [activeTab, setActiveTab] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 10;
  const base_url = 'https://yogsync.com/api'; 

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        
        const response = await axios.get(`${base_url}/user/articalcategory/getall`);
        if (response.data.status_code === 200) {
          setCategories(response.data.data);
          setActiveTab('All'); 
        }
      } catch (error) {
        console.error('Error fetching categories', error);
      }
    };

    fetchCategories();
  }, [base_url]);

  useEffect(() => {
    const fetchArticles = async () => {
      setLoading(true);
      try {
        const params = activeTab === 'All' ? {} : { category_id: categories.find(cat => cat.name === activeTab)?.id };
        const response = await axios.get(`${base_url}/user/artical/getall`, { params });
        if (response.data.status_code === 200) {
          setArticles(response.data.data.flatMap(category => category.articles));
        }
      } catch (error) {
        console.error('Error fetching articles', error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [activeTab, categories, base_url]);

  const totalPages = Math.ceil(articles.length / itemsPerPage);
  const paginatedData = articles.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const createSlug = (title) => {
    return title
      .toLowerCase()         // Convert to lowercase
      .replace(/\s+/g, '-')  // Replace spaces with hyphens
      .replace(/[^\w\-]+/g, '') // Remove non-alphanumeric characters except hyphen
      .replace(/\-\-+/g, '-')  // Replace multiple hyphens with a single hyphen
      .trim(); // Remove leading/trailing hyphens
  };

  return (
    <div className="tab-container bg-[#fdf9e7] p-6 w-full mt-10">
      <div className="tab-header flex mb-10 overflow-y-scroll md:overflow-auto lg:overflow-auto">
        {['All', ...categories.map(cat => cat.name)].map((tab) => (
          <button
            key={tab}
            onClick={() => {
              setActiveTab(tab);
              setCurrentPage(1); 
            }}
            className={`tab-button py-2 px-4 text-lg font-semibold ${activeTab === tab ? 'text-[#D7AB0F] border-b-2 border-[#D7AB0F]' : 'text-black'}`}
          >
            {tab}
          </button>
        ))}
      </div>

      {loading ? (
        <Loader />
      ) : (
        <div className="tab-content">
          {
            paginatedData.length > 0 ? (<> <div className="special-images grid grid-cols-12 gap-5 mb-20">
            
              {paginatedData.slice(0, 2).map((item, index) => (
                <Link
                  key={item.id}
                  className={`col-span-12 bg-[#fdf9e7] rounded-lg shadow-md ${index === 0 ? 'md:col-span-7' : 'md:col-span-5'} article-item overflow-hidden transition-transform duration-500 hover:scale-105`}
                  // onClick={() => {
                  //   onArticleClick(item);
                  //   handleTabChange(activeTab);
                  // }}
                  // to={`/articles/${createSlug(item.title)}`}
                  to={{
                    pathname: `/articles/${item.id}/${createSlug(item.title)}`, 
                    // state: item , 
                  }}
                >
                  <div className="image-container relative overflow-hidden">
                    <img
                      src={`${base_url}/${item.image}`}
                      className="object-cover w-full h-[300px] rounded-t-lg"
                      alt={item.title}
                    />
                    <div className="p-5">
                      <HtmlRenderer htmlContent={item.title} styleType="long" />
                      <HtmlRenderer htmlContent={item.short_description} styleType="short" />
                    </div>
                  </div>
                </Link>
              ))}
            </div> <div className="remaining-images grid grid-cols-2 md:grid-cols-4 gap-5">
            {paginatedData.slice(2).map((item) => (
              <Link
                key={item.id}
                className="article-item bg-[#fdf9e7] rounded-lg shadow-md flex flex-col mb-20"
                // onClick={() => {
                //   onArticleClick(item);
                //   handleTabChange(activeTab);
                // }}
                to={{
                  pathname: `/articles/${createSlug(item.title)}/${item.id}`, 
                  state: item , 
                }}
              >
                <div className="image-container relative overflow-hidden transition-transform duration-500 hover:scale-105">
                  <img
                    src={`${base_url}/${item.image}`}
                    className="object-cover w-full h-[200px] rounded-t-lg"
                    alt={item.title}
                  />
                  <div className="p-5">
                    <HtmlRenderer htmlContent={item.title} styleType="long" />
                    <HtmlRenderer htmlContent={item.short_description} styleType="short" />
                  </div>
                </div>
              </Link>
            ))}
          </div></>):(<div className='flex w-full justify-center my-20'>
              <h1 className="text-2xl md:text-5xl lg:text-4xl font-medium  text-black text-center mt-5 md:mt-0 md:text-left lg:text-left">No data found for this article category!</h1>
          </div>)
          }
          {/* <div className="special-images grid grid-cols-12 gap-5 mb-20">
            
            {paginatedData.slice(0, 2).map((item, index) => (
              <div
                key={item.id}
                className={`col-span-12 bg-[#fdf9e7] rounded-lg shadow-md ${index === 0 ? 'md:col-span-7' : 'md:col-span-5'} article-item overflow-hidden transition-transform duration-500 hover:scale-105`}
                onClick={() => {
                  onArticleClick(item);
                  handleTabChange(activeTab);
                }}
              >
                <div className="image-container relative overflow-hidden">
                  <img
                    src={`${base_url}/${item.image}`}
                    className="object-cover w-full h-[300px] rounded-t-lg"
                    alt={item.title}
                  />
                  <div className="p-5">
                    <HtmlRenderer htmlContent={item.title} styleType="long" />
                    <HtmlRenderer htmlContent={item.short_description} styleType="short" />
                  </div>
                </div>
              </div>
            ))}
          </div> */}
          {/* <div className="remaining-images grid grid-cols-2 md:grid-cols-4 gap-5">
            {paginatedData.slice(2).map((item) => (
              <div
                key={item.id}
                className="article-item bg-[#fdf9e7] rounded-lg shadow-md flex flex-col mb-20"
                onClick={() => {
                  onArticleClick(item);
                  handleTabChange(activeTab);
                }}
              >
                <div className="image-container relative overflow-hidden transition-transform duration-500 hover:scale-105">
                  <img
                    src={`${base_url}/${item.image}`}
                    className="object-cover w-full h-[200px] rounded-t-lg"
                    alt={item.title}
                  />
                  <div className="p-5">
                    <HtmlRenderer htmlContent={item.title} styleType="long" />
                    <HtmlRenderer htmlContent={item.short_description} styleType="short" />
                  </div>
                </div>
              </div>
            ))}
          </div> */}
          {totalPages > 1 && (
            <div className="pagination flex justify-center items-center mt-6">
              <button onClick={handlePreviousPage} disabled={currentPage === 1} className="mx-2 disabled:opacity-50">
                <img src={require('../../../components/images/left_arrow.png')} className="h-[15px] w-[15px] filter brightness-0 opacity-90" alt="Previous" />
              </button>
              {[...Array(totalPages)].map((_, i) => (
                <button
                  key={i}
                  onClick={() => handlePageChange(i + 1)}
                  className={`mx-1 px-3 py-1 rounded ${currentPage === i + 1 ? 'bg-[#D7AB0F] text-black' : 'hover:bg-[#775f08]'}`}
                >
                  {i + 1}
                </button>
              ))}
              <button onClick={handleNextPage} disabled={currentPage === totalPages} className="mx-2 disabled:opacity-50">
                <img src={require('../../../components/images/right_arrow.png')} className="h-[15px] w-[15px] filter brightness-0 opacity-90" alt="Next" />
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ArticlesTabs;
