
import React from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom'; 

const MenuModal = ({ isOpen, onRequestClose, onMenuButtonClick }) => {
  const navigate = useNavigate(); 

  const handleNavigation = (item) => {
    
    switch (item) {
      case 'HOME':
        navigate('/');
        break;
      case 'ARTICLES':
        navigate('/articles');
        break;
      case 'LEARN YOGA POSES':
        navigate('/learn-yoga-poses');
        break;
      // case 'VIDEO GALLERY':
      //   navigate('/video-gallery');
      //   break;
      case 'ABOUT US':
        navigate('/about-us');
        break;
      case 'CONTACT US':
        navigate('/contact-us');
        break;
      case 'BECOME A CONTRIBUTOR!':
      
        onMenuButtonClick(item);
        break;
      default:
        break;
    }

    onRequestClose(); // Close the modal after navigation
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="fixed m-5 max-w-full bg-white rounded-lg p-5 shadow-lg flex flex-col items-start outline-none"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-50"
    >
      <img
        src={require('../images/close.png')}
        className="h-4 w-4 self-end cursor-pointer"
        onClick={onRequestClose}
        alt="Close"
      />
      <div className="mt-6">
        {/* {['HOME', 'ARTICLES', 'LEARN YOGA POSES', 'VIDEO GALLERY', 'ABOUT US', 'CONTACT US','BECOME A CONTRIBUTOR!'].map((item) => ( */}
        {['HOME', 'ARTICLES', 'LEARN YOGA POSES', 'ABOUT US', 'CONTACT US', 'BECOME A CONTRIBUTOR!'].map((item) => (
          <button
            key={item}
            onClick={() => handleNavigation(item)} // Trigger navigation
            className="bg-transparent text-app-darkestColor border-none rounded-md py-2 px-4 my-2 text-left w-full hover:text-app-color transition-colors"
          >
            {item.replace('_', ' ')}
          </button>
        ))}
      </div>
    </Modal>
  );
};

export default MenuModal;
