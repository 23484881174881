import React, { useState } from 'react';
import { useVideo } from '../../api/hooks/response';
import Loader from '../../components/Loader/Loader';
import VideoGalleryDetails from './components/VideoGalleryDetails';
import DOMPurify from 'dompurify';

const HtmlRenderer = ({ htmlContent, styleType, isList }) => {
    const sanitizeHtml = (html) => {
        return DOMPurify.sanitize(html);
    };
  
    const applyListStyles = (html) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
  
        const uls = tempDiv.querySelectorAll('ul');
        const ols = tempDiv.querySelectorAll('ol');
  
        uls.forEach((ul) => {
            if (isList) {
                ul.classList.add('list-disc', 'pl-5', 'pr-5', 'grid', 'grid-cols-1', 'gap-2', 'md:gap-x-20', 'md:gap-y-0', 'md:grid-cols-2');
            } else {
                ul.classList.add('list-disc', 'pl-5');
            }
        });
  
        ols.forEach((ol) => {
            ol.classList.add('list-decimal', 'pl-5');
        });
  
        return tempDiv.innerHTML;
    };
  
    const processedHtml = applyListStyles(sanitizeHtml(htmlContent));
  
    const getStyleClass = () => {
        switch (styleType) {
            case 'short':
                return 'text-medium font-medium text-text-primary'; // Apply specific Tailwind classes
            case 'long':
                return 'text-large font-medium text-text-secondary w-[90%]'; // Apply different Tailwind classes
            case 'list':
                return 'text-text-highlight font-medium pr-10'; // Style for lists
            default:
                return 'text-base text-text-primary'; // Default styling
        }
    };
  
    return (
        <div
            className={getStyleClass()}
            dangerouslySetInnerHTML={{ __html: processedHtml }}
        />
    );
  };

const VideoGallery = () => {
    const { video, loading, error } = useVideo();
    const [currentPage, setCurrentPage] = useState(1);
    const videosPerPage = 5;
    const [selectedVideo, setSelectedVideo] = useState(null);
    // No filtering based on level, so directly use video array
    const totalPages = Math.ceil(video.length / videosPerPage);
    const paginatedVideos = video.slice(
        (currentPage - 1) * videosPerPage,
        currentPage * videosPerPage
    );

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleVideoClick = (video) => {
        setSelectedVideo(video);
    };

    const handleCloseDetails = () => {
        setSelectedVideo(null);
    };

    const extractVideoId = (url) => {
        const match = url.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:embed\/|watch\?v=|v\/)?([^"&?\/\s]{11})/);
        return match ? match[1] : '';
    };

    return (
        <div className="flex flex-col bg-white  z-20 py-10 px-5 md:py-0 lg:px-0 md:px-0 lg:py-0">
            {selectedVideo ? (
                <VideoGalleryDetails video={selectedVideo} onClose={handleCloseDetails} />
            ) : (<>
                {/* <div className="bg-[#fdf9e7] relative mb-10">
                <img src={require('../../components/images/yoga_bg.png')} className="absolute top-10 right-0 bottom-0 left-10 md:left-[30vw] lg:left-[30vw] h-1/1 w-auto opacity-[0.1] z-0" layout="fill" objectFit="cover" alt="Background Image" />
                <img src={require('../../components/images/yoga_bg.png')} className="absolute bottom-0 -left-10 -top-10 right-0 md:right-[30vw] lg:right-[30vw] h-[200px] w-auto opacity-[0.1] z-0" layout="fill" objectFit="cover" alt="Background Image" />
                <div className='p-5 pt-10 pb-12 pl-18 z-10'>
                    <h1 className="text-[#D7AB0F] text-4xl font-semibold leading-[48px] font-serif mb-0">Video Gallery</h1>
                    <h6 className="text-[#0d6773] text-base font-semibold text-left mt-7 mb-7 w-[100%]  font-serif">
                        Explore our extensive video library featuring guided yoga sessions, tutorials, and meditation practices. Perfect for all levels, these videos will help you deepen your practice and enhance your well-being.
                    </h6>
                    <button className="bg-[#D7AB0F] text-white rounded-full py-3 px-5 text-base font-normal hover:bg-[#0d6773]">
                        Start Your 14-Day Free Trial
                    </button>
                </div>
            </div> */}
                <div className="bg-[#fdf9e7] relative" style={{ backgroundImage: `url(${require('../../components/images/section_Header.png')})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                    <div className=' p-5 pt-10 pb-12 pl-18 z-10'>
                        <h1 className="text-[#6D5302] text-4xl font-semibold leading-[48px] font-serif mb-0">Video Gallery</h1>
                        <h6 className="text-[#FFFEFE] text-base font-semibold text-left mt-7 mb-7 md:w-[60%] w-[90%]  font-serif">
                            Explore our extensive video library featuring guided yoga sessions, tutorials, and meditation practices. Perfect for all levels, these videos will help you deepen your practice and enhance your well-being.
                        </h6>
                        <button className="bg-[#6D5302] text-[#EACA57] rounded-full py-3 px-5 text-base font-normal hover:bg-[#D7AB0F]">
                            Start Your 14-Day Free Trial
                        </button>
                    </div>
                </div>
                {
                    loading ? (
                        <Loader />
                    ) : (
                        <>
                            {paginatedVideos.map((item, index) => {
                                const videoId = extractVideoId(item.url);

                                return (
                                    <div key={index} className="flex flex-row my-5 gap-10 px-5 md:px-10 md:mx-5 py-2 md:py-0  items-center border rounded-lg border-[#f9e59f]  p-0  z-20 flex-wrap md:flex-nowrap lg:flex-nowrap" onClick={() => handleVideoClick(item)}>
                                        {videoId ? (
                                            <div className="relative w-[100%] md:w-[40%] lg:w-[40%] h-[90%] justify-center  flex  py-10">
                                                <img
                                                    src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
                                                    alt="YouTube Video Thumbnail"
                                                    // onClick={() => window.open(item.url, "_blank")}
                                                    className=" w-[90%] h-[90%] rounded-lg"
                                                //  style={{ borderRadius: '1rem' }} 
                                                />
                                                <img
                                                    src={require('../../components/images/play.png')}
                                                    className="w-[20px] h-[20px] md:w-[30px] lg:w-[30px] md:h-[30px] lg:h-[30px] absolute top-[40%] left-[40%] md:top-[50%] lg:top-[50%] md:left-[50%] lg:left-[50%]"
                                                    alt="Play"
                                                />
                                            </div>
                                        ) : (
                                            <div className="text-red-500">Thumbnail not available</div>
                                        )}
                                        <div className=" gap-5 flex flex-col w-[100%] md:w-[70%] lg:w-[70%] justify-center">
                                            <h2 className="text-[#D7AB0F] text-3xl font-extrabold text-center md:text-start  lg:text-start">{item.title}</h2>
                                            {/* <p className="text-black text-base font-medium text-center md:text-start  lg:text-start">{item.description}</p> */}
                                            <HtmlRenderer htmlContent={item.description} styleType="short" />
                                        </div>
                                    </div>
                                );
                            })}

                            {totalPages > 1 && (
                                <div className="flex justify-center items-center mt-6">
                                    <img src={require('../../components/images/left_arrow.png')} onClick={handlePreviousPage}
                                        disabled={currentPage === 1} className="mx-2  disabled:opacity-50 h-[15px] w-[15px] filter brightness-0 opacity-90" alt="Previous" />
                                    {[...Array(totalPages)].map((_, i) => (
                                        <button
                                            key={i}
                                            onClick={() => setCurrentPage(i + 1)}
                                            className={`mx-1 px-3 py-1 rounded ${currentPage === i + 1 ? 'bg-[#D7AB0F] text-black' : 'hover:bg-[#775f08]'}`}
                                        >
                                            {i + 1}
                                        </button>
                                    ))}
                                    <img src={require('../../components/images/right_arrow.png')} onClick={handleNextPage}
                                        disabled={currentPage === totalPages} className="mx-2  disabled:opacity-50 h-[15px] w-[15px] filter brightness-0 opacity-90" alt="Next" />
                                </div>
                            )}
                        </>
                    )
                }

                <div className='flex flex-col items-center my-10 mx-2 md:mx-20 lg:mx-20 gap-5'>
                    <h1 className="text-black text-3xl font-semibold text-center">Get the best yoga stories into your inbox</h1>
                    <div className='flex flex-row md:w-[50%] lg:w-[50%] flex-wrap gap-2 md:gap-0 md:flex-nowrap' >
                        <input placeholder="Enter Your Email Address" inputMode="text" className="border border-gray-300 px-8 py-5 text-black w-full" />
                        <button className="bg-[#D7AB0F] rounded-full w-[50%] md:w-auto py-5 md:py-0 px-8 text-lg text-white font-normal text-center border-none font-poppins ml-4 hover:bg-[#14535C] cursor-pointer">
                            SUBSCRIBE
                        </button>
                    </div>
                    <p className="text-black text-base font-medium text-center">By entering your email address, you agree to Shvasa's Privacy Policy.</p>
                </div>
            </>
            )}
        </div>
    );
};

export default VideoGallery;
