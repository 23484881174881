import React, { useState, useEffect } from 'react';
import { useExpertApprovedArticles, useLatestArticles } from '../../api/hooks/response';
import CardHorizontalScrollView from './CardHorizontalScrollView';
import ExpertsPick from './ExpertsPick';
import ArticlesDetails from '../Articles/components/ArticlesDetails';
import { articles, yogaGuide } from '../../utils';
import Loader from '../../components/Loader/Loader';
import YogaPosesDetail from './YogaPosesDetail';
import LearnYogaPoses from '../LearnYogaPoses/LearnYogaPoses';
import { Link, useNavigate } from 'react-router-dom';

const Home = () => {
  const { latestArticles, loading: latestLoading, error: latestError } = useLatestArticles();
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [selectedPose, setSelectedPose] = useState(null); // State for selected yoga pose
  const [isMobile, setIsMobile] = useState(false);
  const { allexpert: expertPick = [], loading: expertLoading, error: expertError } = useExpertApprovedArticles();
  const navigate = useNavigate()

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleArticleClick = (article) => {
    setSelectedArticle(article);
  };

  const handlePoseClick = (pose) => {
    setSelectedPose(pose); // Set the selected yoga pose
  };

  const handleCloseDetails = () => {
    setSelectedArticle(null);
  };

  const handleBackToPoses = () => {
    setSelectedPose(null); // Clear the selected yoga pose and return to the list
  };

  useEffect(() => {
    console.log(expertPick, "expertPickexpertPickexpertPickexpertPick")
  }, [expertPick])


  if (latestLoading) return <Loader />;
  if (latestError) return <p>Error loading articles...</p>;

  if (expertLoading) return <Loader />;
  if (expertError) return <p>Error loading articles...</p>;

  return (
    <div className="flex flex-col bg-white">
      {/* Conditionally render ArticlesDetails if an article is selected */}
      {selectedArticle ? (
        <ArticlesDetails article={selectedArticle} onClose={handleCloseDetails} />
      ) : selectedPose ? ( // Conditionally render YogaPosesDetail if a pose is selected
        <YogaPosesDetail pose={selectedPose.title} onClose={handleBackToPoses} />
      ) : (
        <>
          {/* Video Section */}
          <div className="relative w-full mt-0 pt-0">
            <img
              src={require('../../components/images/main_page.jpg')}
              className="w-full h-full object-contain mt-0 pt-0"
              alt="Main"
            />
            <div className="h-[90%] md:h-auto absolute gap-5 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 px-4 md:px-10 lg:px-10 items-start justify-start flex flex-col w-full md:w-full lg:w-full py-0 md:py-0 lg:py-0">
              <h2 className="text-xl md:text-6xl lg:text-6xl font-bold text-[#6D5302] leading-tight font-serif w-full md:w-full text-left">
              The Yoga Guide For a Relaxed  <br />Body and Mind
              </h2>
              <h5 className="text-xs md:text-3xl lg:text-3xl font-medium text-[#FFFEFE] mt-0 md:mt-4 w-[70%] md:w-[50%] lg:w-[50%] text-left">
              Embrace the freedom, peace, and mindfulness with yoga practice!
              </h5>
              <button className="mt-0 bg-[#6D5302] text-[#EACA57] py-2 px-6 md:py-3 md:px-12 md:mt-6 lg:mt-6 rounded-full font-semibold">
              Know more
              </button>
            </div>
          </div>

          {/* Latest Articles Section */}
          <div className="relative flex flex-col items-center justify-center bg-[#fcf2cf] py-10">
            {/* <img
              src={require('../../components/images/yoga_bg.png')}
              className="absolute top-0 right-0 opacity-[0.1] -mr-40 -mt-20 "
              alt=""
            />
            <img
              src={require('../../components/images/yoga_bg.png')}
              className="absolute bottom-0 left-0 opacity-[0.1] -ml-40 -mb-20"
              alt=""
            /> */}
            <img
              src={require('../../components/images/Frame.png')}
              className="my-5 w-1/4 filter brightness-0 opacity-90"
              alt=""
            />
            <h1 className="text-[#D7AB0F] text-4xl font-semibold mb-8 font-serif">
              Latest Articles
            </h1>
            <CardHorizontalScrollView items={latestArticles} onArticleClick={handleArticleClick} />
          </div>

          {/* Yoga Poses Section */}
          <div className="flex flex-col items-center bg-white px-5 mb-10 border rounded-lg border-[#fcf2cf] z-10 py-10 mt-5 md:px-10 md:mx-5">
            <img
              src={require('../../components/images/Frame.png')}
              className="my-5 w-1/4 filter brightness-0 opacity-90"
              alt=""
            />

            <h1 className="text-[#D7AB0F] text-4xl font-semibold mb-8 font-serif text-center">
            The Beginning of Your Yoga Journey 

            </h1>

            {yogaGuide.map((item, index) => (
              <div
                key={item.id}
                className={`flex  mx-50  justify-center flex-wrap md:flex-nowrap lg:flex-nowrap ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'} `}
                style={{
                  backgroundImage: isMobile
                    ? 'none'
                    : `url(${index % 2 === 0
                      ? require('../../components/images/yogaPoses_left.png')
                      : require('../../components/images/yogaPoses_right.png')})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                }}
                // onClick={() => handlePoseClick(item)} 
                onClick={() => navigate("/learn-yoga-poses", { state: item })}

              // to={{
              //   pathname: '/learn-yoga-poses',
              //   state: index   // Pass `item` here
              // }}
              >
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-[70%] h-auto object-cover p-10 bg-[#EFD78369] rounded-full md:rounded-none md:bg-transparent md:w-[35%]"
                />

                <div className="flex flex-col justify-center w-[100%] md:ml-10 bg-[#EFD78369] rounded-lg p-10 md:p-0 mb-10 md:rounded-none md:bg-transparent md:w-[80%]">
                  <h2 className={`text-2xl md:text-5xl  mt-10 font-semibold  text-[#D7AB0F] ${index % 2 === 0 ? 'pr-10' : 'pl-10'}`}>{item.title}</h2>
                  <p className={`text-lg text-black mt-2 ${index % 2 === 0 ? 'pr-10' : 'pl-10'}`}>{item.description}</p>
                </div>
              </div>
            ))}
          </div>

          <ExpertsPick items={expertPick} />
        </>
      )}
    </div>
  );
};

export default Home;
