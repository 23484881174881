
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const MetaTags = ({ metaTags }) => {
  // Default to a fallback title if metaTags is an empty string or undefined
  const title = metaTags && metaTags.replace(/"/g, ' ')
  console.log(metaTags, "titletitle.", title)
  useEffect(() => {
    if (metaTags) {
      const head = document.querySelector('head');
      head.innerHTML += metaTags;
    }
  }, [metaTags]);
  return null
  // return null;
  // return (
  //   <Helmet>
  //     {/* Inject the cleaned meta tags */}
  //     {/* <title>{title}</title> */}
  //     {/* <meta
  //      dangerouslySetInnerHTML={title}
  //     /> */}
  //     <head>{metaTags}</head>
  //   </Helmet>
  // );
};


export default MetaTags;

