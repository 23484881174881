const AboutUs = () => {
    return (
        <div className="flex flex-col bg-white pb-10 z-20 gap-10 py-10 md:py-0  lg:py-0 mb-10">
            <div className="bg-[#fdf9e7] relative" style={{ backgroundImage: `url(${require('../../components/images/section_Header.png')})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                <div className=' p-5 pt-10 pb-12 pl-18 z-10'>
                    <h1 className="text-[#6D5302] text-4xl font-semibold leading-[48px] font-serif mb-0">Our Story and Mission</h1>
                    <h6 className="text-[#FFFEFE] text-base font-semibold text-left mt-7 mb-7 md:w-[60%] w-[90%]  font-serif">
                        A wise person once called yoga an art of being good to oneself and the same though drives us. We work to be the medium to stay grounded and surrounded by wellness practices. From physical to mental health, we aim to walk hand-in-hand with you to promote all of it.

                    </h6>
                </div>
            </div>
            <div className='flex flex-row gap-10 flex-wrap md:flex-nowrap lg:flex-nowrap mt-5 md:mt-5 lg:mt-5 md:mx-10  px-5 border rounded-lg border-[#fcf2cf] z-10 py-10 md:px-10 '>
                <div className='flex w-[100%] md:w-[50%] lg:w-[50%]'>
                    <img src={require('../../components/images/about_us_yoga.png')} className='w-full h-auto object-cover rounded-xl' />
                </div>
                <div className='flex w-[100%] md:w-[50%] lg:w-[50%] flex-col gap-5'>
                    <h4 className='text-[#D7AB0F] text-4xl font-bold p-0'>Yoga Journey</h4>
                    <p className='text-black text-xl font-medium'>One’s yoga journey begins with a strong foundation we aim to build for everyone.  For us, yoga is a constant walk of self-discovery and development. It lets one connect and understand self, gain mental clarity, and find emotional balance. However, Rome wasn’t built in a day, and the same goes for yoga enlightenment. At YOG SYNC, we simplify this time gap and walk hand-in-hand throughout your yoga journey.

                        <br /><br />
                        From beginner’s to advanced-level yoga poses, we have brought all out of traditional and classic essence. We have simplified gaining an understanding of yoga and let everyone obtain its benefits in one place. We work to create simple and in-depth learning methods to transform the lives of millions.

                        <br /><br />
                        Our set of guides and information has been built on in-depth research from authentic sources. We have traced the history of yoga and put our passion into it. Yoga has been transforming lives, ours, yours, and everyone’s, and we work to let everyone take its advantage.

                    </p>
                </div>
            </div>
            <div className='flex flex-col gap-10 flex-wrap md:flex-nowrap lg:flex-nowrap mt-5 md:mt-5 lg:mt-5 md:mx-10  px-5 border rounded-lg border-[#fcf2cf] z-10 py-10 md:px-10 '>
                <h4 className='text-[#D7AB0F] text-4xl font-bold p-0'>Our Philosophy</h4>
                <p className='text-black text-xl font-medium mt-0'>Digging deeper into the essence of yoga, we call it the ideal blend of physical, mental, and emotional practices. Its origins go ages ago, traces of them can be found in ancient scriptures like Rigveda and many other Upnashids.
                    <br /><br />
                    Today, yoga has become a much-advanced form, including the present-day form of Hatha Yoga. Its set of techniques covers relaxation, stress relief, and physical fitness, with posture as the base. Collectively, it has given us a way to rise above present-day, hectic, and disoriented lifestyles.
                    <br /><br />
                    As the name suggests, YOG SYNC is about going back to when Yog originated and bringing ancient and present-day insights about it.

                </p>
            </div>
            <div className='flex flex-col gap-10 flex-wrap md:flex-nowrap lg:flex-nowrap mt-5 md:mt-5 lg:mt-5 border rounded-lg border-[#fcf2cf] z-10 py-10 px-5 md:px-10 md:mx-5'>
                <h4 className='text-[#D7AB0F] text-4xl font-bold p-0'>Counting on YOG SYNC</h4>
                <p className='text-black text-xl font-medium mt-0'>How great the world has become with tech influencing our lives? Well, that’s what we want to make the best use of, all while simplifying practicing yoga from anywhere, anytime. Well, that’s not all, and here is what makes us different!
                    <br />
                    <br />
                    <span className='text-[#D7AB0F] text-2xl font-bold '>Clear and Easy Instructions: </span>Our yoga guides and articles are crafted to provide a set of clear and easy-to-understand instructions. So that you can learn at your own pace. 
                    <br />
                    <br />
                    <span className='text-[#D7AB0F] text-2xl font-bold'>Diverse Offerings: </span>Not just one or two or tens, but we offer guidance to many yoga poses you can learn about. From beginner to advanced, find the information about all the postures.
                    <br />
                    <br />
                    <span className='text-[#D7AB0F] text-2xl font-bold'>Expert Advice and Guidance
                    : </span>We have brought in-depth researched articles and guides created through verified resources to provide original and reliable information.
                    <br />
                    <br />
                    <span className='text-[#D7AB0F] text-2xl font-bold'>A Beginner’s Friendly Space
                    : </span> If you are conscious about performing in front of others or want to learn at your own pace, YOG SYNC is the place to begin. 
                    <br />
                    <br />
                    YOG SYNC is the stop for a wide range of yoga guides crafted to meet the needs of a wide range of learners. Get, set, and start your yoga journey today!

                </p>
            </div>
        </div>
    )
}

export default AboutUs