import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BecomeContributor = ({ isOpen, onRequestClose }) => {
  // State to manage form fields and errors
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  });

  const [errors, setErrors] = useState({});


  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({})
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    setErrors({})
  }, [isOpen])

  const validateForm = () => {
    const newErrors = {};

    if (!formData.firstName) newErrors.firstName = 'First Name is required';
    if (!formData.lastName) newErrors.lastName = 'Last Name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }
    if (!formData.phone) {
      newErrors.phone = 'Phone Number is required';
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = 'Phone Number must be 10 digits';
    }
    if (!formData.message) newErrors.message = 'Message is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // const handleSubmit = () => {
  //   if (validateForm()) {

  //     console.log('Form submitted:', formData);
  //     onRequestClose();
  //   }
  // };

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailData = {
      to_name: 'Yogsync',
      from_name: formData.fullName,
      message: formData.message,
      from_email: formData.email
    };
    if (validateForm()) {
      emailjs.send('service_fwph7h9', 'template_8ye2mar', emailData, 'WcSa42N6XWzJw9Ld2')
        .then((response) => {
          console.log('Email sent successfully:', response.status, response.text);
          console.log(response, "response..")
          toast("Your message has been sent successfully!")
          setFormData({ fullName: '', email: '', phone: '', message: '' });
          setErrors({});
        })
        .catch((error) => {
          console.error('Error sending email:', error);

          toast.error("There was an error sending your message. Please try again.")
        });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="m-5 bg-white rounded-lg p-8 shadow-lg flex flex-col items-center outline-none w-full max-w-6xl"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center"
    >
      <ToastContainer />
      <img
        src={require('../images/close.png')}
        className="h-4 w-4 self-end cursor-pointer mb-3"
        onClick={onRequestClose}
        alt="Close"
      />

      <div className="flex justify-center w-full mb-4">
        <img src={require('../images/AppLogo.PNG')} className="h-16 w-auto" alt="App Logo" />
      </div>

      <h2 className="text-xl font-bold mb-2 text-center w-full">Work with Us</h2>
      <p className="text-sm mb-6 text-center w-full text-black">Fill out the form below to become a contributor.</p>

      <div className="grid grid-cols-2 gap-6 w-full mb-4">
        <div className="flex flex-col">
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            value={formData.firstName}
            onChange={handleChange}
            className={`border ${errors.firstName ? 'border-red-500' : 'border-[#D7AB0F]'} rounded-lg px-4 py-2 w-full`}
          />
          {errors.firstName && <span className="text-red-500 text-xs">{errors.firstName}</span>}
        </div>
        <div className="flex flex-col">
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={formData.lastName}
            onChange={handleChange}
            className={`border ${errors.lastName ? 'border-red-500' : 'border-[#D7AB0F]'} rounded-lg px-4 py-2 w-full`}
          />
          {errors.lastName && <span className="text-red-500 text-xs">{errors.lastName}</span>}
        </div>
        <div className="flex flex-col">
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className={`border ${errors.email ? 'border-red-500' : 'border-[#D7AB0F]'} rounded-lg px-4 py-2 w-full`}
          />
          {errors.email && <span className="text-red-500 text-xs">{errors.email}</span>}
        </div>
        <div className="flex flex-col">
          <input
            type="tel"
            name="phone"
            placeholder="Phone Number"
            value={formData.phone}
            onChange={handleChange}
            className={`border ${errors.phone ? 'border-red-500' : 'border-[#D7AB0F]'} rounded-lg px-4 py-2 w-full`}
          />
          {errors.phone && <span className="text-red-500 text-xs">{errors.phone}</span>}
        </div>
      </div>

      <div className="flex flex-col w-full mb-6 mt-6">
        <textarea
          name="message"
          placeholder="Message"
          value={formData.message}
          onChange={handleChange}
          className={`border ${errors.message ? 'border-red-500' : 'border-[#D7AB0F]'} rounded-lg px-4 py-2 w-full h-24 resize-none`}
        />
        {errors.message && <span className="text-red-500 text-xs">{errors.message}</span>}
      </div>

      <button
        className="bg-[#D7AB0F] w-[30%] rounded-full py-3 px-4 text-sm text-black font-medium text-center border-none font-poppins hover:bg-[#D7AB0F] cursor-pointer"
        onClick={handleSubmit}
      >
        Send
      </button>
    </Modal>
  );
};

export default BecomeContributor;
