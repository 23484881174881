import React, { useEffect, useState } from 'react';
import LearnYogaPoses from '../../LearnYogaPoses/LearnYogaPoses';
import LearnYogaPosesDetail from '../../LearnYogaPoses/components/LearnYogaPosesDetail';
import Loader from '../../../components/Loader/Loader';
import DOMPurify from 'dompurify';
import { useParams, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import MetaTags from '../../../components/MetaTag';


const HtmlRenderer = ({ htmlContent, styleType, isList }) => {
  const sanitizeHtml = (html) => {
    return DOMPurify.sanitize(html);
  };

  const applyListStyles = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    const uls = tempDiv.querySelectorAll('ul');
    const ols = tempDiv.querySelectorAll('ol');

    uls.forEach((ul) => {
      if (isList) {
        ul.classList.add('list-disc', 'pl-5', 'pr-5', 'grid', 'grid-cols-1', 'gap-2', 'md:gap-x-20', 'md:gap-y-0', 'md:grid-cols-2');
      } else {
        ul.classList.add('list-disc', 'pl-5');
      }
    });

    ols.forEach((ol) => {
      ol.classList.add('list-decimal', 'pl-5');
    });

    return tempDiv.innerHTML;
  };

  const processedHtml = applyListStyles(sanitizeHtml(htmlContent));

  const getStyleClass = () => {
    switch (styleType) {
      case 'short':
        return 'text-medium font-medium text-primary'; // Apply specific Tailwind classes
      case 'long':
        return 'text-large font-medium text-secondary w-[90%]'; // Apply different Tailwind classes
      case 'list':
        return 'text-text-highlight font-medium pr-10'; // Style for lists
      default:
        return 'text-base text-primary'; // Default styling
    }
  };

  return (
    <div
      className={getStyleClass()}
      dangerouslySetInnerHTML={{ __html: processedHtml }}
    />
  );
};

const ArticlesDetails = ({ onClose, activeTab }) => {
  const location = useLocation();
  const { slug, id } = useParams();
  // const { article } = location.state || {};
  console.log(id, "??", slug)
  const [selectedPose, setSelectedPose] = useState(null);
  const [article, SetArticle] = useState()
  const base_url = 'https://yogsync.com/api';

  const handlePoseClick = (pose) => {
    setSelectedPose(pose);
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${base_url}/user/artical/get/${id}`);
      if (response.data.status_code === 200) {
        const fetchedCategories = response.data;
        console.log(response.data.data)
        SetArticle(response.data.data)
      }
    } catch (error) {
      console.error('Error fetching categories', error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, [id]);

  if (selectedPose) {
    return (
      <LearnYogaPosesDetail
        pose={selectedPose}
        onClose={() => setSelectedPose(null)}
        activeTab="LearnYogaPoses"
      />
    );
  }

  const createSlug = (title) => {
    return title
      .toLowerCase()         // Convert to lowercase
      .replace(/\s+/g, '-')  // Replace spaces with hyphens
      .replace(/[^\w\-]+/g, '') // Remove non-alphanumeric characters except hyphen
      .replace(/\-\-+/g, '-')  // Replace multiple hyphens with a single hyphen
      .trim(); // Remove leading/trailing hyphens
  };

  // const cleanMetaTag = (metaTag) => {
  //   try {
  //     // Remove the escaping and get the proper HTML tag
  //     return JSON.parse(metaTag); // Parse the string to get the proper HTML
  //   } catch (error) {
  //     console.error('Error parsing meta tag:', error);
  //     return ''; // Return an empty string if the parsing fails
  //   }
  // };
  // const cleanMetaTag = (metaTag) => {
  //   try {
  //     // Remove escape characters (if any) and clean the string
  //     const cleanedTag = metaTag.replace(/\\"/g, '"').replace(/\\\\/g, '\\');  // Remove escape characters

  //     // Use DOMParser to parse the cleaned HTML string into a document
  //     const parser = new DOMParser();
  //     const doc = parser.parseFromString(cleanedTag, 'text/html');

  //     // Now return the entire body of the parsed HTML document (or specific parts if needed)
  //     return doc; // This returns the whole parsed HTML content as DOM nodes
  //   } catch (error) {
  //     console.error('Error parsing meta tag:', error);
  //     return null;  // Return null in case of error
  //   }
  // };
  // function extractHtmlContent(str) {
  //   // Step 1: Remove non-HTML content and extract everything between < and >
  //   const matches = str.match(/<[^>]+>/g); // Match everything between < and >
  // console.log(matches,"..",str)
  //   // Step 2: Return all matched HTML content or an empty string if no match is found
  //   return matches ? matches.join('') : '';
  // }
  

  
  // function cleanMetaTag(metaTag) {
  //   // Optional: Clean or sanitize the metaTag string as needed
  //   // let cleanedMetaTag = {
  //   //   __html: metaTag, // Wrap the metaTag string in the __html property
  //   // };
  
  //   // Optional: Check the cleaned string to see if it contains valid HTML
  //   const cleanedMetaTag = extractHtmlContent(metaTag);
  
  //   console.log("Cleaned Meta Tag: ", cleanedMetaTag);
  //   return cleanedMetaTag;
  // }
  function cleanMetaTag(metaTag) {

    let cleanedMetaTag = metaTag
      .replace(/\\\"/g, '')
      .replace(/\\\\/g, '')
      .replace(/\\+/g, '')
      .replace(/"/g,'');

    // Optional: Check the cleaned string to see if it contains valid HTML
    console.log("Cleaned Meta Tag: ", cleanedMetaTag);

    return cleanedMetaTag;
  }

  if (!article) {
    return <Loader />
  }

  return (

    <div className="article-detail-view bg-white my-10 md:my-0 lg:my-0">

      {
        article.map((article) => {
          const cleanedMetaTag = cleanMetaTag(article.meta_tag); // Clean the meta tag here
          return (
            <div className="flex flex-col px-3 md:px-5 lg:px-5" onClick={() => console.log(cleanedMetaTag, "..")}>
              <MetaTags metaTags={cleanedMetaTag} />
              
              <div className='flex flex-col md:flex-row-reverse lg:flex-row-reverse justify-start'>
                <div className='flex md:w-[50%] lg:w-[50%] items-center justify-center'>
                  <img
                    src={`https://yogsync.com/api/${article.image}`}
                    className="w-full object-contain h-auto md:h-[60vh] lg:h-[60vh]"
                    alt={article.title}
                  />
                </div>

                <div className='flex flex-col gap-5 md:gap-10 lg:gap-10 items-start justify-center relative pb-0  pt-10 md:py-0 lg:py-0 md:w-[50%] lg:w-[50%]'>
                  {/* <img
                src={require('../../../components/images/yoga_bg.png')}
                className="absolute top-0 right-0 left-0 bottom-0 opacity-[0.1]"
                alt=""
              /> */}
                  <div className='flex flex-row items-center gap-5 relative'>
                    <Link
                      className="bg-[#D7AB0F] rounded-[5px] py-3 px-4 text-sm text-black font-normal text-center border-none font-poppins hover:bg-[#D7AB0F] cursor-pointer hover:underline"
                      // onClick={onClose}
                      to="/articles"
                    >
                      Back to results
                    </Link>
                    <p className="text-black text-lg font-medium text-start hover:text-[#D7AB0F] cursor-pointer hover:underline" onClick={onClose}>Articles</p>
                    <img className='w-[15px] h-[15px] filter brightness-0 opacity-90' src={require('../../../components/images/right_arrow.png')} />
                    <p className="text-black text-lg font-medium text-start hover:text-[#D7AB0F] cursor-pointer hover:underline" onClick={onClose}>{activeTab}</p>
                  </div>
                  <div className='flex flex-col justify-center gap-10 mt-10'>
                    <div className='flex flex-col gap-2'>
                      <p className="text-[#D7AB0F] text-3xl font-bold text-start">{article.title}</p>
                      {/* <p className="text-black text-lg font-normal text-start">{article.short_description}</p> */}
                      <HtmlRenderer htmlContent={article.short_description} styleType="long" />
                    </div>
                    <div className='flex flex-row gap-5 relative'>
                      <a
                        href="https://www.facebook.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Facebook"
                      >
                        <img src={require('../../../components/images/facebook_gray.png')} className='w-[25px] h-[25px] object-cover filter brightness-0 opacity-100' alt="Facebook" />
                      </a>
                      <a
                        href="https://twitter.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Twitter"
                      >
                        <img src={require('../../../components/images/twitter_gray.png')} className='w-[25px] h-[25px] object-cover filter brightness-0 opacity-100' alt="Twitter" />
                      </a>
                      <a
                        href="https://www.pinterest.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Pinterest"
                      >
                        <img src={require('../../../components/images/pintrust_gray.png')} className='w-[25px] h-[25px] object-cover filter brightness-0 opacity-100' alt="Pinterest" />
                      </a>
                      <a
                        href="https://wa.me/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="WhatsApp"
                      >
                        <img src={require('../../../components/images/whatsapp_gray.png')} className='w-[25px] h-[25px] object-cover filter brightness-0 opacity-100' alt="WhatsApp" />
                      </a>
                    </div>
                    <p className="text-black text-lg font-normal text-start mt-5 md:mt-20 lg:mt-20">
                      by <span className='text-[#D7AB0F] font-semibold'>{article.author_name}</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className='my-10 w-[100%] flex flex-col'>
                <div className='flex flex-col gap-5 border rounded-lg border-[#fcf2cf] z-10 py-10 px-5 md:px-10 md:mx-5'>
                  <p className="text-[#D7AB0F] text-3xl font-bold text-start mt-10">{article.title}</p>
                  {/* <p className="text-black text-2xl font-semibold text-start">{article.short_description}</p> */}
                  <HtmlRenderer htmlContent={article.short_description} styleType="short" />
                  {/* <p className="text-black text-lg font-medium text-start">{article.long_description}</p> */}
                  <HtmlRenderer htmlContent={article.long_description} styleType="long" />
                </div>

                {article.related_yoga_poses ? (article.related_yoga_poses.length > 0 && (
                  <div className='flex flex-col  gap-10  '>
                    <p className='text-[#D7AB0F] text-3xl font-bold text-start mt-10 '>Related Yoga Poses</p>

                    {article.related_yoga_poses.map((item, index) => (
                      <Link
                        key={index}
                        className='flex flex-row gap-5 border rounded-lg border-[#fcf2cf] py-10 px-5 md:py-5 lg:py-5 md:px-0 lg:px-0 cursor-pointer flex-wrap md:flex-nowrap lg:flex-nowrap'
                        // onClick={() => handlePoseClick(item)}
                        to={{
                          pathname: `/learn-yoga-poses/${item.id}/${createSlug(item.name)}`,
                          // state: poseData , 
                        }}
                      >
                        <div className='w-[100%] md:w-[40%] lg:w-[40%] items-center justify-center md:justify-start lg:justify-start  flex'>
                          <img src={`https://yogsync.com/api/${item.image}`} alt={item.name} className='w-[90%] h-auto rounded-l-lg' />
                        </div>
                        <div className='flex flex-col gap-7 justify-center'>
                          <p className="text-[#D7AB0F] text-4xl font-extrabold text-center md:text-start  lg:text-start">{item.name}</p>
                          {/* <p className="text-black text-xl font-bold text-center md:text-start  lg:text-start">{item.description}</p> */}
                          <HtmlRenderer htmlContent={item.description} styleType="long" />
                        </div>
                      </Link>
                    ))}

                  </div>)
                ) : <></>
                }

                <div className='my-10 w-[90%] flex flex-col items-center justify-center'>
                  <h4 className="text-[#D7AB0F] text-3xl font-semibold text-start mt-10">Share article</h4>
                  <div className='flex flex-row gap-8 mt-5'>
                    <a
                      href="https://www.facebook.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Facebook"
                    >
                      <img src={require('../../../components/images/facebook_gray.png')} className='w-[25px] h-[25px] object-cover filter brightness-0 opacity-100' alt="Facebook" />
                    </a>
                    <a
                      href="https://twitter.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Twitter"
                    >
                      <img src={require('../../../components/images/twitter_gray.png')} className='w-[25px] h-[25px] object-cover filter brightness-0 opacity-100' alt="Twitter" />
                    </a>
                    <a
                      href="https://www.pinterest.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Pinterest"
                    >
                      <img src={require('../../../components/images/pintrust_gray.png')} className='w-[25px] h-[25px] object-cover filter brightness-0 opacity-100' alt="Pinterest" />
                    </a>
                    <a
                      href="https://wa.me/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="WhatsApp"
                    >
                      <img src={require('../../../components/images/whatsapp_gray.png')} className='w-[25px] h-[25px] object-cover filter brightness-0 opacity-100' alt="WhatsApp" />
                    </a>
                    <a
                      href="javascript:void(0);"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Copy Link"
                    >
                      <img src={require('../../../components/images/copyLink_gray.png')} className='w-[25px] h-[25px] object-cover filter brightness-0 opacity-100' alt="Copy Link" />
                    </a>
                  </div>
                </div>

                <div className='flex flex-row items-center justify-center gap-10 mt-20 w-[100%] flex-wrap md:flex-nowrap lg:flex-nowrap md:items-start lg:items-start md:justify-start lg:justify-start'>
                  <img
                    src={`https://yogsync.com/api/${article.author_image}`}
                    className="w-1/2 h-[20vh] md:w-20 lg:w-20 md:h-20 lg:h-20 rounded-full mt-2 flex"
                    alt={article.author_name}
                  />
                  <div className='flex flex-col '>
                    <h3 className="text-[#D7AB0F] text-2xl font-semibold">{article.author_name}</h3>
                    <p className="text-black text-lg font-normal mt-2">{article.author_details}</p>
                  </div>
                </div>
              </div>
            </div>)
        }
        )
      }

    </div>
  );
};

export default ArticlesDetails;
